import styled from 'styled-components';
import { GrView } from "react-icons/gr";

export const RootContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	background: #000;
	height: 100vh;

	@media only screen and (orientation:portrait) {
		flex-direction: column;
	}
`;

export const AbsoluteContainer = styled.div`
	position: absolute;
	top: 15%;
	left: 100px;
	z-index: 10000;
`;

export const GenerateEmailButton = styled.div`
	padding: 20px;
	background-color: ${({theme}) => theme.purple};
	color: ${({theme}) => theme.white};
	cursor: pointer;
	border-radius: 10px;
`;

export const GenerateEmailForm = styled.div`
	width: 400px;
	height: 450px;
	background-color: ${({theme}) => theme.purple};
	border-radius: 10px;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const EmailSummaryInput = styled.textarea`
	margin: 5px;
	padding: 5px 10px;
	color: ${({theme}) => theme.darkGrey};
	border: none;
	outline: none;
	border-radius: 5px;
	background-color: transparent;
	width: calc(100% - 40px);
	height: 100%;
	color: white;
	font-size: 1rem;
	resize: none;
`;

export const VideoContainer = styled.div.attrs({id: "something"})`
	flex: 3;
	background: #000;
	position: relative;

	@media only screen and (orientation:portrait) {
		flex: 1;
		margin-bottom: 5px;
		overflow-y: hidden;
	}
`;

export const SummaryContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	background: ${({theme}) => theme.darkGrey};
	text-align: left;
	min-height: 0;
	min-width: 0;

	@media only screen and (orientation:portrait) {
		flex: 3;
	}

	@media only screen and (max-width: 850px) {
		flex: 1.5;
	}

	@media only screen and (max-width: 500px) and (orientation:landscape) {
		position: absolute;
		right: 0;
		z-index: 2;
		width: 35%;
		height: 100vh;
	}
`;

export const SearchContainer = styled.div`
	flex: 1;
	display: flex;
	justify-content: flex-end;
	border-radius: 1rem;
`;


export const SummaryListContainer = styled.div`
	flex: 1;
	height: 100%;
	overflow-y: scroll;
	scrollbar-width: none;
	&:-webkit-scrollbar {
		display: none;
	}
`;

	// background: ${({theme}) => theme.purple};
export const SummaryContainerHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: ${props => props.background ? `#${props.background}` : props.theme.purple};
	padding: 10px;
`

export const SummaryContainerText = styled.div`
	color: ${({theme}) => theme.white};
	font-weight: bolder;
	@media only screen and (max-width: 650px)
	{
		font-size: 18px;	
	}
	@media only screen and (max-width: 500px)
	{
		font-size: 14px;
	}
`;

export const SummaryCard = styled.div`
	border-left: ${(props) => props.focused?'10px':'5px'} solid ${(props) => props.focused?`#${props.border}`:props.theme.metallic};
	padding: 5px;
	padding-left: 10px;
	color: ${({theme}) => theme.white};
	border-bottom: 1px solid ${(props) => props.theme.lightGrey};
	transition: all 0.2s ease;
	cursor: pointer;
	@media only screen and (max-width: 550px)
	{
		border-left: ${(props) => props.focused?'6px':'4px'} solid ${(props) => props.focused?`#${props.border}`:props.theme.metallic};
	}
`;

export const SummaryTitle = styled.div`
	font-size: 20px;
	margin-bottom: 5px;
	wordBreak: break-all;

	@media only screen and (max-width: 750px)
	{
		font-size: 18px;
	}
	@media only screen and (max-width: 650px)
	{
		font-size: 16px;	
	}
	@media only screen and (max-width: 500px)
	{
		font-size: 14px;
	}

`;

export const SummaryDescription = styled.div`
	font-size: 14px;
	color: ${({theme}) => theme.metallic};
`;

export const SummaryCardTopBar = styled.div`
	display: flex;
	color: ${({theme}) => theme.metallic};
	align-items: center;
	text-align: left;
`;

export const SummaryCardTopBarLeftPiece = styled.div`
	flex: 1;
	font-size: 14px;
	color: ${({theme}) => theme.metallic};
`;

export const SummaryCardTopBarRightPiece = styled.div`
	flex: 1;
	display: flex;
	justify-content: flex-end;
	font-size: 12px;
	font-weight: bold;
	color: ${({theme}) => theme.metallic};
`;

export const ViewIcon = styled(GrView)`
	color: ${({theme}) => theme.metallic}
`;

export const SummaryCardButtonBar = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
`;

export const SummaryCardButtonContainer = styled.div`
	cursor: pointer;
`;

export const NoSummariesContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	padding: 10px;
`;

export const NoSummariesText = styled.div`
	text-align: center;
	font-size: 1.1em;
	font-weight: bold;
	color: ${({theme}) => theme.white};
`;

export const AddChapterButton = styled.div`
	display: inline-block;
	padding: 10px;
	border-radius: 5px;
	background: ${({theme}) => theme.purple};
	color: ${({theme}) => theme.white};
	font-weight: bold;
	cursor: pointer;
	transition: all 0.3s ease;

	&:hover {
		color: ${({theme}) => theme.purple};
		background: ${({theme}) => theme.white};
	}

	&:active {
		transform: scale(0.8);
	}
`;

export const InputContainer = styled.div`
	display: flex;
	align-items: center;
	border-radius: 5px;
	margin: 10px;
	color: ${({theme}) => theme.lightGrey};
`;

export const InputLabel = styled.div`
	color: ${({theme}) => theme.white};
`;

export const SummaryInput = styled.input.attrs({type: 'text'})`
	margin: 5px;
	padding: 5px 10px;
	color: ${({theme}) => theme.darkGrey};
	border: none;
	outline: none;
	border-radius: 5px;
`;

export const EmailSummaryContainer = styled.div`
	width: calc(100% - 40px);
	padding: 10px 20px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	color: white;

	div {
		color: white;
		padding: 10px;
		border-radius: 10px;
		border: 1px solid #fff;
		margin-right: 5px;
		cursor: pointer;
	}

	span {
		color: white;
		margin-left: 5px;
	}
`;

export const SummaryDescriptionInput = styled.textarea.attrs({cols: 27, rows: 3})`
	margin: 5px;
	padding: 5px 10px;
	color: ${({theme}) => theme.darkGrey};
	border: none;
	outline: none;
	border-radius: 5px;
`;

export const SummaryDurationInput = styled.input.attrs({type: 'text', size: 1, maxLength: 2})`
	margin: 5px;
	padding: 5px 10px;
	color: ${({theme}) => theme.darkGrey};
	border: none;
	outline: none;
	border-radius: 5px;
`;

export const VideoTitleContainer = styled(SearchContainer)`
	display: flex;
	padding: 5px;
	background: ${({theme}) => theme.darkGrey}
`;

export const VideoTitleText = styled(NoSummariesText)`
	flex: 1;
	font-size: 1em;
	font-weight: normal;
	text-align: left;
`;
export const CopyContentContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 5px;
	margin: 10px;
	border-radius: 5px;
	border: 1px solid ${({theme}) => theme.metallic};
	cursor: pointer;

	
	@media only screen and (max-width: 570px)
	{
		display: ${(props) => props.className === 'embed-btn' ? 'none' : 'flex'};
	}
`;

export const CopyIconContainer = styled.div`
	border-radius: 50%;
	padding: 10px;
`;

export const CopyTextContainer = styled.div`
	// flex: 1;
	// flex-basis: 0;
	padding: 10px;
`;

export const CopyTextHeading = styled.div`
	font-size: 1.3em;
	font-weight: bold;
	color: ${({theme}) => theme.white};
`;

export const CopyTextContent = styled.div`
	font-size: 18px;
	color: ${({theme}) => theme.metallic};
`;