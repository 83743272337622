import {
	fetchTrailerAsyncActions,
	actionButtonAsyncActions,
	createSessionAsyncActions,
	videoEndedAsyncActions,
	endTimeUpdateAsyncActions,
} from './actions';

const initialState = {
	isLoading: false,
	isError: false,
	videoIds: [],
	trailerId: '',
	title: '',
	description: '',
	shortDescription: '',
	actionButtonText: '',
	actionButtonLink: '',
	actionButtonColor: '',
	actionButtonTextColor: '',
	channel: ''
}

export default (state = initialState, action) => {
	switch(action.type) {
		case fetchTrailerAsyncActions.actionNames.request: 
			return {...state, ...initialState, isLoading: true}

		case fetchTrailerAsyncActions.actionNames.success: 
			return {...state, ...action.payload, trailerId: action.payload._id, isLoading: false}

		case fetchTrailerAsyncActions.actionNames.error: 
			return {...state, isLoading: false}

		case createSessionAsyncActions.actionNames.request: 
			return {...state, ...initialState, isLoading: true}

		case createSessionAsyncActions.actionNames.success: 
			return {...state, ...action.payload, sessionId: action.payload._id, isLoading: false}

		case createSessionAsyncActions.actionNames.error: 
			return {...state, isLoading: false}

		default:
			return state;
	}
}