import {call, put, takeLeading} from 'redux-saga/effects';
import {
	fetchTrailerAsyncActions,
	playTrailerAsyncActions,
	actionButtonAsyncActions,
	createSessionAsyncActions,
	videoEndedAsyncActions,
	endTimeUpdateAsyncActions,
} from './actions';
import {
	fetchTrailer,
	playTrailer,
	trailerVideos,
	createTrailer,
	endTimeUpdate,
	actionButtonClick,
	videoEnded,
} from './api';
import {toast} from 'react-toastify';

function* getTrailerSaga(action) {
	try {
		// console.log("SAGA", action)
		const response = yield call(fetchTrailer, action.payload);
		// console.log('Initial response count', response.data.result);
		yield put(fetchTrailerAsyncActions.success(response.data.result))
		// console.log("getTrailerSaga Response", response.data.result)
	}
	catch(err) {
		yield put(fetchTrailerAsyncActions.error())
	}
}

function* playTrailerSaga(action) {
	try {
		const response = yield call(playTrailer, action.payload.trailerId, action.payload.videoId);
		yield put(playTrailerAsyncActions.success(response.data.result))
	}
	catch(err) {
		yield put(playTrailerAsyncActions.error())
	}
}

function* actionButtonTrailerSaga(action) {
	try {
		const response = yield call(actionButtonClick, action.payload);
		yield put(actionButtonAsyncActions.success(response.data.result))
	}
	catch(err) {
		yield put(actionButtonAsyncActions.error())
	}
}

function* createSessionSaga(action) {
	try {
		const response = yield call(createTrailer, action.payload);
		yield put(createSessionAsyncActions.success(response.data.result))
	}
	catch(err) {
		yield put(createSessionAsyncActions.error())
	}
}

function* endTimeUpdateSaga(action) {
	try {
		const response = yield call(endTimeUpdate, action.payload);
		yield put(endTimeUpdateAsyncActions.success(response.data.result))
	}
	catch(err) {
		yield put(endTimeUpdateAsyncActions.error())
	}
}

function* videoEndedSaga(action) {
	try {
		const response = yield call(videoEnded, action.payload);
		yield put(videoEndedAsyncActions.success(response.data.result))
	}
	catch(err) {
		yield put(videoEndedAsyncActions.error())
	}
}


function* mainSaga() {
	yield takeLeading(fetchTrailerAsyncActions.actionNames.request, getTrailerSaga);
	yield takeLeading(playTrailerAsyncActions.actionNames.request, playTrailerSaga);
	yield takeLeading(actionButtonAsyncActions.actionNames.request, actionButtonTrailerSaga);
	yield takeLeading(createSessionAsyncActions.actionNames.request, createSessionSaga);
	yield takeLeading(endTimeUpdateAsyncActions.actionNames.request, endTimeUpdateSaga);
	yield takeLeading(videoEndedAsyncActions.actionNames.request, videoEndedSaga);
}

export default mainSaga;