import axios from "axios";

const baseUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000"
    : "https://api.chapterme.co";

export default () => {
  axios.defaults.baseURL = baseUrl;
  // axios.defaults.baseURL = "https://devserver.chapterme.co";
  // axios.defaults.baseURL = "http://localhost:5000";
  // axios.defaults.baseURL = "http://localhost:5001";
};
