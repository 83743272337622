import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
`;
export const IframeContainer = styled.div`
  opacity: ${({ opacityFactor }) => (opacityFactor ? 0 : 1)};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const PlayButtonContainer = styled.div`
  position: absolute;
  top: calc(50% - 35px);
  left: calc(50% - 35px);
  background-color: ${({ theme, background }) =>
    background ? "#" + background : theme.purple};
  width: 7rem;
  height: 7rem;
  border-radius: 100px;
  margin: auto auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    width: 3rem;
    height: 3rem;
  }

  .play-button {
    margin-left: 5px;
    @media only screen and (max-width: 600px) {
      width: 2rem;
      height: 2rem;
    }
  }
`;

export const PlaceHolderThumbnail = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  margin: auto 0px;
  object-fit: contain;

  & > img {
    height: 100%;
    width: 100%;
  }
`;

export const IframePlayer = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  transition: width 300ms;

  ${(props) =>
    !props.trailer
      ? `
		@media only screen and (orientation:portrait) {
			position: relative;
			height: ${
        window.innerHeight <= (window.innerWidth * 9) / 16
          ? window.innerHeight
          : (window.innerWidth * 9) / 16
      }px;
		}


		@media only screen and (orientation:landscape) {
			height: 100%;
		}
		`
      : `height: 100vh;`}
`;

// position: absolute;
export const VimeoIframe = styled.iframe.attrs((props) => ({
  title: "vimeo-player",
  id: "vimeo-player",
  src: `${props.url}`,
}))`
  top: 0;
  left: 0;
  width: 100%;

  @media only screen and (orientation: portrait) {
    position: relative;
    height: ${window.innerHeight <= (window.innerWidth * 9) / 16
      ? window.innerHeight
      : (window.innerWidth * 9) / 16}px;
  }

  @media only screen and (orientation: landscape) {
    height: 100vh;
  }
`;
