import styled from 'styled-components';
import {AiFillHome} from 'react-icons/ai'

export const RootContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	margin: 0;
	padding: 0;
`;

export const Jumbotron = styled.div`
	background: ${({theme}) => theme.purple};
	// width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	padding: 20px;
`;
export const MenuHomeIcon = styled(AiFillHome)`
	position: absolute;
	top: 20px;
	left: 20px;
	height: 2.3rem;
	width: 2.3rem;
	// padding: 10px;
	border-radius: 5px;
	margin: 0 15px;
	color: ${({theme}) => theme.halfWhite};
	background: ${({theme}) => theme.purple};
	cursor: pointer;
`;
export const TitleImage = styled.img`
	position: absolute;
	top: 25px;
	right: 20px;
	width: 150px;
	height: auto;
	cursor: pointer;
`;

export const PageTitle = styled.div`
	font-size: 2em;
	color: ${({theme}) => theme.white};
	font-weight: bold;
`;

export const BodyContainer = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${({theme}) => theme.white};
`;

export const ColorPickerContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
	padding: 10px;
`;

export const IframeContainer = styled.div`
	position: relative;
	flex: 4;
	height: 90%;
	background-color: ${({theme}) => theme.halfWhite};
	margin: 10px;
	padding: 10px 20px;
`;

export const LoadMoreButton = styled.div`
	display: inline-block;
	margin: 10px;
	padding: 10px 20px;
	cursor: pointer;
	background: ${({theme}) => theme.halfWhite};
	color: ${({theme}) => theme.lightGrey};
	transition: all 0.3s ease;

	${props => !props.disabled
		?
		`&:hover {
			transform: scale(1.01);
		}

		&:active {
			transform: scale(0.95);
		}`
		:
		null
	}
`;