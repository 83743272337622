import {useState, useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
// import axios from 'axios';
import {
	RootContainer,
	Jumbotron,
	SearchInput,
	SearchInputContainer,
	RecommendationContainer,
	RecommendedSection,
	RecommendedSectionTitle,
	RecommendedSectionViewMore,
	RecommendedSectionScrollable,
	RecommendedSlide,
	// RecommendedSlideImage,
	RecommendedSlideTitle,
	RecommendedSlideSubtitle,
	SearchResultContainer,
	SearchResultScrollable,
	// YoutubeThumbnail,
	SearchResult,
	SearchVideoTitle,
	SearchVideoSubtitle,
	SearchBarContainer,
	RecommendationLoadingContainer,
	ChannelImageContainer,
	ChannelImage,
	TitleImage,
	// SearchButton,
} from './Home.styles';
// import {IoSearch} from 'react-icons/io5';
import moment from 'moment';
import {homeActions} from 'modules/home';
import {useHistory} from 'react-router-dom';
import RingLoader from 'react-spinners/RingLoader';

import {Thumbnail} from 'components';

const Home = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const homeState = useSelector(state => state.homeReducer, shallowEqual)
	const [searchText, setSearchText] = useState('');
	const [isSearchFocused, setIsSearchFocused] = useState('');

	const onSearch = (value) => {
		// console.log("SEARCH", value)
		setSearchText(value)
		dispatch(homeActions.searchAsyncActions.request({searchTerm: value, query: {skip: 0, limit: 20}}))
	}


	useEffect(() => {
		dispatch(homeActions.trendingAsyncActions.request())
		dispatch(homeActions.recommendedAsyncActions.request({skip: 0, limit: 20}))
		dispatch(homeActions.channelListAsyncActions.request())
	}, [dispatch])
	// console.log("HOME STATE", isSearchFocused);
	return (
		<RootContainer>
			<Jumbotron>
				<TitleImage src="https://chapterme.co/assets/img/logo-white.svg" onClick={() => window.open("https://chapterme.co", "_blank") }/>
				<span style={{textAlign: 'center'}}><b>Take a look around and explore our videos &amp; podcasts engaging millions of users</b><div className="select-nothing" onClick={() => window.open("https://admin.chapterme.co/auth/?source=sign-up&loc=platform", "_blank")}>Create your own →</div></span>
				{/*<SearchButton onClick={() => history.push(`/search`)} >
					<IoSearch size='1em' style={{margin: '5px', alignSelf: 'center'}} color='#23272a' />
					Search Videos
				</SearchButton>*/}
				<SearchInputContainer>
					<SearchBarContainer>
						<SearchInput onChange={evt => onSearch(evt.target.value)} onFocus={() => setIsSearchFocused(true)} onBlur={() => setTimeout(() => setIsSearchFocused(false), 500)} />
						{
								homeState.isSearchLoading
								?
								<RingLoader size={20} color="#23272a" />
								:
								null
						}
					</SearchBarContainer>
						<SearchResultScrollable>
							{
								searchText && isSearchFocused
								?
								homeState.searchedVideos.map(video => (
									<SearchResultContainer key={video.videoTitle}onClick={() => window.open(`${window.location.origin}/player/video/${video.videoId}`)}>
										<Thumbnail fromSearch={true} video={video} />
										<SearchResult>
											<SearchVideoTitle>{video.videoTitle && video.videoTitle.length < 60 ? video.videoTitle : video.videoTitle.substring(0, 70)+'...'}</SearchVideoTitle>
											<SearchVideoSubtitle>{moment.duration(video.summary[video.summary.length-1].start*1000).format(`${video.summary[video.summary.length-1].start > 60?'hh:mm:ss':'mm:ss'}`, {trim: video.summary[video.summary.length-1].start > 60?true:false})} | {video.summary.length} Chapters</SearchVideoSubtitle>
										</SearchResult>
									</SearchResultContainer>
								))
								:
								null
							}
							{
								searchText
								?
								<RecommendedSectionViewMore onClick={() => history.push(`/search?searchText=${searchText}`)} >
									View video chapters
								</RecommendedSectionViewMore>
								:
								null
							}
						</SearchResultScrollable>
				</SearchInputContainer>
			</Jumbotron>
			<RecommendationContainer>
				<RecommendedSection>
					<RecommendedSectionTitle className="select-nothing" >
						Trending Now
						{/*<RecommendedSectionViewMore onClick={() => history.push('/recentVideos')} >
							{' '}
						</RecommendedSectionViewMore>*/}
					</RecommendedSectionTitle>
					<RecommendedSectionScrollable>
						{
							homeState.isTrendingLoading
							?
							<RecommendationLoadingContainer>
								<RingLoader size={60} color="#23272a" />
							</RecommendationLoadingContainer>
							:
							homeState.trendingVideos.map((video, ind) => (
								<RecommendedSlide key={`trendingVideos${ind}`} onClick={() => window.open(`${window.location.origin}/player/video/${video.videoId}`)} >
									<Thumbnail video={video} />
									<RecommendedSlideTitle>{video.videoTitle.length < 60 ? video.videoTitle : video.videoTitle.substring(0, 60)+'...'}</RecommendedSlideTitle>
									<RecommendedSlideSubtitle>{moment.duration(video.summary[video.summary.length-1].start*1000).format(`${video.summary[video.summary.length-1].start > 60?'hh:mm:ss':'mm:ss'}`, {trim: video.summary[video.summary.length-1].start > 60?true:false})} | {video.summary.length} Chapters</RecommendedSlideSubtitle>
									<RecommendedSlideSubtitle>{video.viewCount} Views</RecommendedSlideSubtitle>
								</RecommendedSlide>
							))
						}
					</RecommendedSectionScrollable>
				</RecommendedSection>
			</RecommendationContainer>
			<RecommendationContainer>
				<RecommendedSection>
					<RecommendedSectionTitle className="select-nothing">
						Recently Added
						<RecommendedSectionViewMore onClick={() => history.push('/recentVideos')} >
							View more
						</RecommendedSectionViewMore>
					</RecommendedSectionTitle>
					<RecommendedSectionScrollable>
						{
							homeState.isRecommendedLoading
							?
							<RecommendationLoadingContainer>
								<RingLoader size={60} color="#23272a" />
							</RecommendationLoadingContainer>
							:
							homeState.recommendedVideos.map((video, ind) => (
								<RecommendedSlide key={`recommended${ind}`} onClick={() => window.open(`${window.location.origin}/player/video/${video.videoId}`)} >
									<Thumbnail video={video} />
									<RecommendedSlideTitle>{video.videoTitle.length < 60 ? video.videoTitle : video.videoTitle.substring(0, 60)+'...'}</RecommendedSlideTitle>
									<RecommendedSlideSubtitle>{moment.duration(video.summary[video.summary.length-1].start*1000).format(`${video.summary[video.summary.length-1].start > 60?'hh:mm:ss':'mm:ss'}`, {trim: video.summary[video.summary.length-1].start > 60?true:false})} | {video.summary.length} Chapters</RecommendedSlideSubtitle>
									<RecommendedSlideSubtitle>{video.viewCount} Views</RecommendedSlideSubtitle>
								</RecommendedSlide>
							))
						}
					</RecommendedSectionScrollable>
				</RecommendedSection>
			</RecommendationContainer>
			<RecommendationContainer>
				<RecommendedSection>
					<RecommendedSectionTitle className="select-nothing">
						Channels
						{/*<RecommendedSectionViewMore>
							{' '}
						</RecommendedSectionViewMore>*/}
					</RecommendedSectionTitle>
					<RecommendedSectionScrollable>
						{
							homeState.isChannelListLoading
							?
							<RecommendationLoadingContainer>
								<RingLoader size={60} color="#23272a" />
							</RecommendationLoadingContainer>
							:
							homeState.channelList.map(channel => (
								<RecommendedSlide key={channel.channelName} onClick={() => window.open(`${window.location.origin}/channel/${channel.channelName}`)} >
									<ChannelImageContainer background={channel.bannerColor} >
										<ChannelImage src={channel.logo} />
									</ChannelImageContainer>
									<RecommendedSlideTitle>{channel.displayName}</RecommendedSlideTitle>
								</RecommendedSlide>
							))
						}
					</RecommendedSectionScrollable>
				</RecommendedSection>
			</RecommendationContainer>
		</RootContainer>
	)
}

export default Home;

/*
<RecommendedSlide key={video.videoTitle} onClick={() => window.open(`${window.location.origin}/player/video/${video.videoId}`)} >
									<RecommendedSlideImage src={thumbUrlGenerator(video.platform, video.videoId)} />
									<RecommendedSlideTitle>{video.videoTitle.length < 30 ? video.videoTitle : video.videoTitle.substring(0, 30)+'...'}</RecommendedSlideTitle>
									<RecommendedSlideSubtitle>{moment.duration(video.summary[video.summary.length-1].start*1000).format('hh:mm:ss')} | {video.summary.length} Chapters</RecommendedSlideSubtitle>
								</RecommendedSlide>
*/