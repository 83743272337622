import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import {
  Garage,
  Login,
  Chapter,
  Home,
  Channel,
  VideoList,
  ErrorPage,
  Embed,
  EmbedSettings,
  Trailer,
  TrailerSelector,
} from "../screens";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

const ProtectedRoute = ({ children, ...rest }) => {
  const authState = useSelector((state) => state.authReducer, shallowEqual);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authState.loginSuccess ? children : <Redirect to="/" />
      }
    />
  );
};

export default () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <ProtectedRoute path="/account">
          <Home />
        </ProtectedRoute>
        <Route path="/player/video/:videoId">
          <Garage />
        </Route>
        <Route path="/embed/video/:videoId">
          <Embed />
        </Route>
        <Route path="/embedSettings/:videoId">
          <EmbedSettings />
        </Route>
        <Route path="/share/video/:videoId/chapter/:start">
          <Chapter />
        </Route>
        <Route path="/channel/:channelName">
          <Channel />
        </Route>
        <Route path="/trailer/:videoId">
          <Trailer />
        </Route>
        <Route path="/trailerSelection/:trailerId">
          <TrailerSelector />
        </Route>
        <Route path="/recentVideos">
          <VideoList />
        </Route>
        <Route path="/search">
          <VideoList />
        </Route>
        <Route path="*">
          <ErrorPage />
        </Route>
      </Switch>
    </Router>
  );
};
