import {call, put, select, takeLeading} from 'redux-saga/effects';
import {
	createSessionAsyncActions,
	videoEndedAsyncActions,
	endTimeUpdateAsyncActions,
} from './actions';
import {
	createSession,
	endTimeUpdate,
	videoEnded,
} from './api';

function* createSessionSaga(action) {
	try {
		const response = yield call(createSession, action.payload);
		yield put(createSessionAsyncActions.success(response.data.result))
	}
	catch(err) {
		yield put(createSessionAsyncActions.error())
	}
}

function* endTimeUpdateSaga(action) {
	try {
		const response = yield call(endTimeUpdate, action.payload);
		yield put(endTimeUpdateAsyncActions.success(response.data.result))
	}
	catch(err) {
		yield put(endTimeUpdateAsyncActions.error())
	}
}

function* videoEndedSaga(action) {
	try {
		const sessionId = yield select(state => state.embedReducer.sessionId)
		const response = yield call(videoEnded, sessionId);
		yield put(videoEndedAsyncActions.success(response.data.result))
	}
	catch(err) {
		yield put(videoEndedAsyncActions.error())
	}
}


function* mainSaga() {
	yield takeLeading(createSessionAsyncActions.actionNames.request, createSessionSaga);
	yield takeLeading(endTimeUpdateAsyncActions.actionNames.request, endTimeUpdateSaga);
	yield takeLeading(videoEndedAsyncActions.actionNames.request, videoEndedSaga);
}

export default mainSaga;