import axios from "axios";

export const fetchTrailer = (trailerId) => {
  return axios.get("/trailer/get/" + trailerId);
};

export const playTrailer = (trailerId, videoId) => {
  return axios.get("/trailer/play/" + trailerId + "/" + videoId);
};

export const createTrailer = (trailerId) => {
  return axios.post("/session/trailer/create", { trailerId });
};

export const endTimeUpdate = ({ sessionId, videoId, endTime }) => {
  return axios.post("/session/trailer/endTime", {
    sessionId,
    videoId,
    endTime,
  });
};

export const actionButtonClick = ({
  sessionId,
  actionButtonLocation,
  actionButtonTimestamp,
}) => {
  return axios.post("/session/trailer/actionButton", {
    sessionId,
    actionButtonLocation,
    actionButtonTimestamp,
  });
};

export const videoEnded = ({ sessionId }) => {
  return axios.post("/session/trailer/videoEnded", { sessionId });
};
