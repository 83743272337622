import {
  fetchSummariesAsyncActions,
  mutateSummariesAction,
  postSummariesAsyncActions,
  fetchEmbedSummariesAsyncActions,
  shareEmbedChapterAsyncAction,
  viewEmbedChapterAsyncAction,
} from "./actions";

const initialState = {
  isLoading: false,
  isError: false,
  videoId: "",
  summaries: [],
  isMutated: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case fetchSummariesAsyncActions.actionNames.request:
      return {
        ...state,
        summaries: [],
        videoTitle: "",
        platform: "",
        isLoading: true,
      };

    case fetchSummariesAsyncActions.actionNames.success: {
      return {
        ...state,
        summaries: action.payload.summary,
        videoTitle: action.payload.videoTitle,
        platform: action.payload.platform,
        isLoading: false,
        channel: action.payload.channel,
        logo: action.payload.logo,
        icon: action.payload.icon,
      };
    }

    case fetchSummariesAsyncActions.actionNames.error:
      return { ...state, isLoading: false };

    case fetchEmbedSummariesAsyncActions.actionNames.request:
      return {
        ...state,
        summaries: [],
        videoTitle: "",
        platform: "",
        isLoading: true,
      };

    case fetchEmbedSummariesAsyncActions.actionNames.success:
      return {
        ...state,
        summaries: action.payload.summary,
        videoTitle: action.payload.videoTitle,
        platform: action.payload.platform,
        logo: action.payload.logo,
        icon: action.payload.icon,
        thumbnail: action.payload.thumbnail,
        isLoading: false,
      };

    case fetchEmbedSummariesAsyncActions.actionNames.error:
      return { ...state, isLoading: false };

    case postSummariesAsyncActions.actionNames.request:
      return { ...state, isLoading: true };

    case postSummariesAsyncActions.actionNames.success:
      return { ...state, isLoading: false };

    case mutateSummariesAction.actionName: {
      return { ...state, isMutated: true, ...action.payload };
    }

    default:
      return state;
  }
};
