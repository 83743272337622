import {useEffect} from 'react';

import {
	// RootContainer,
	BannerContainer,
	BannerLogo,
	ChannelDetailContainer,
	ChannelDetailContentContainer,
	Divider,
	ChannelDescriptionTitle,
	ChannelDescription,
	CallToActionButton,
	RecommendationContainer,
	RecommendedSection,
	RecommendedSectionTitle,
	// RecommendedSectionViewMore,
	RecommendedSectionScrollable,
	RecommendedSlide,
	// RecommendedSlideImage,
	RecommendedSlideTitle,
	RecommendedSlideSubtitle,
	RecommendationLoadingContainer,
	ChannelShareButton,
	// ThumbImage,
	TitleImage
} from './Channel.styles';
import Skeleton from 'react-loading-skeleton';
import {PreviewedVideo, Thumbnail} from 'components';
import { useParams } from "react-router-dom";
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {channelActions} from 'modules/channel';
import {useHistory} from 'react-router-dom';
import { MdShare } from "react-icons/md";
import moment from 'moment';
import RingLoader from 'react-spinners/RingLoader';
import {toast} from 'react-toastify';
// import { BiPaste } from "react-icons/bi";
import copy from 'clipboard-copy';

const Channel = () => {
	const dispatch = useDispatch()
	const { channelName } = useParams();
	const history = useHistory();
	const channelState = useSelector(state => state.channelReducer, shallowEqual)

	const onCopyLink = () => {
		const link = `https://api.chapterme.co/share/channel/${channelName}`
		copy(link)
		toast.success('Link copied successfully', {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}

	const onVideoClick = (videoId, startTime) => {
		window.open(`${window.location.origin}/player/video/${videoId}${startTime?'?startTime='+startTime:''}`)
	}

	useEffect(() => {
		dispatch(channelActions.feturedVideosAsyncActions.request(channelName))
	}, [dispatch])

	useEffect(() => {
		if(channelState.channelData === null) {
			history.push('/error')
			return null;
		}
		document.title = channelState.channelData.displayName ? channelState.channelData.displayName+" | ChapterMe" : 'ChapterMe Platform - Table Of Contents For All Your Videos and Podcast'

		// console.log("CHANNEL STATE", channelState)
	}, [channelState])

	if(channelState.isFeaturedLoading){
		return (
			<div style={{flex: 1}} >
				<Skeleton height={300} />
				<ChannelDetailContainer>
					<ChannelDetailContentContainer>
						<ChannelDescriptionTitle>About</ChannelDescriptionTitle>
						<ChannelDescription>
							<Skeleton count={5}/>
						</ChannelDescription>
						<Skeleton width={100} height={50} />
					</ChannelDetailContentContainer>
					<Divider background="#ccc" />
					<ChannelDetailContentContainer>
						<Skeleton height={230} />
					</ChannelDetailContentContainer>
				</ChannelDetailContainer>
				<RecommendationContainer>
					<RecommendedSection>
						<RecommendedSectionTitle>
							Recently Added
						</RecommendedSectionTitle>
						<RecommendedSectionScrollable>
							<Skeleton height={250} />
						</RecommendedSectionScrollable>
					</RecommendedSection>
				</RecommendationContainer>
			</div>
		)
	}

	if(channelState.channelData !== null) {
		return (
			<div style={{flex: 1}} >
				<BannerContainer background={channelState.channelData.bannerColor} >
					<TitleImage src="https://chapterme.co/assets/img/logo-white.svg" onClick={() => window.open("https://chapterme.co", "_blank") }/>
					<ChannelShareButton onClick={onCopyLink} >
						<MdShare size='1.5em' style={{margin: '5px'}} color='#333' />
					</ChannelShareButton>
					<BannerLogo src={channelState.channelData.logo} />
				</BannerContainer>
				<ChannelDetailContainer>
					<ChannelDetailContentContainer>
						<ChannelDescriptionTitle>About</ChannelDescriptionTitle>
						<ChannelDescription>
							{channelState.channelData.description}
						</ChannelDescription>
						<CallToActionButton background={channelState.channelData.themeColor} onClick={() => window.open(`${channelState.channelData.website}`)} >Visit Website</CallToActionButton>
					</ChannelDetailContentContainer>
					<Divider background="#ccc" />
					<ChannelDetailContentContainer>
						<PreviewedVideo forChannel={true} onClick={() => window.open(`${window.location.origin}/player/video/${channelState.featuredVideos[0].videoId}`)} onVideoClick={onVideoClick} summary={channelState.featuredVideos[0]} Thumbnail={Thumbnail} />
					</ChannelDetailContentContainer>
				</ChannelDetailContainer>
				<RecommendationContainer>
					<RecommendedSection>
						<RecommendedSectionTitle>
							Recently Added
						</RecommendedSectionTitle>
						<RecommendedSectionScrollable>
							{
								channelState.isFeaturedLoading
								?
								<RecommendationLoadingContainer>
									<RingLoader size={60} color="#23272a" />
								</RecommendationLoadingContainer>
								:
								channelState.featuredVideos.map((video, ind) => (
									<RecommendedSlide key={`${ind+''+video.videoTitle}`}  onClick={() => window.open(`${window.location.origin}/player/video/${video.videoId}`)} >
										<Thumbnail video={video} />
										<RecommendedSlideTitle>{video.videoTitle.length < 60 ? video.videoTitle : video.videoTitle.substring(0, 60)+'...'}</RecommendedSlideTitle>
										<RecommendedSlideSubtitle>{moment.duration(video.summary[video.summary.length-1].start*1000).format('hh:mm:ss')} | {video.summary.length} Chapters</RecommendedSlideSubtitle>
									</RecommendedSlide>
								))
							}
						</RecommendedSectionScrollable>
					</RecommendedSection>
				</RecommendationContainer>
			</div>
		)
	}

	return null;
}

export default Channel;