import styled from 'styled-components';

const GlassyCover = styled.div`
	position: absolute;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background: rgba(0,0,0,0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99;
`;

const Container = styled.div`
	// width: 60vw;
	height: auto;
	background: ${({theme}) => theme.darkGrey};
	border-radius: 10px;
	padding: 15px;

	@media only screen and (orientation:portrait) {
		width: 90vw;
	}
`;

const Modal = ({children, close}) => (
	<GlassyCover onClick={close} >
		<Container onClick={() => null} >
			{children}
		</Container>
	</GlassyCover>
)

export default Modal;