import {call, put, takeLeading} from 'redux-saga/effects';
import {loginAsyncActions} from './actions';
import {toast} from 'react-toastify';
import {login} from './api';

function* loginSaga(action) {
	try {
		const response = yield call(login, action.payload);
		yield put(loginAsyncActions.success(response.data))
	}
	catch(err) {
		yield put(loginAsyncActions.error())
		toast.error('Something went wrong', {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
}

function* mainSaga() {
	yield takeLeading(loginAsyncActions.actionNames.request, loginSaga)
}

export default mainSaga;