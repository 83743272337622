import {call, put, takeLeading, takeLatest} from 'redux-saga/effects';
import {searchAsyncActions, loadMoreSearchAsyncActions, recommendedAsyncActions, channelListAsyncActions, trendingAsyncActions} from './actions';
import {recentVideos, searchVideos, getChannels, trendingVideos} from './api';
import {toast} from 'react-toastify';

function* getRecommendedSaga(action) {
	try {
		const response = yield call(recentVideos, action.payload);
		yield put(recommendedAsyncActions.success(response.data.result))
	}
	catch(err) {
		// console.log("RECOMMENDATIONS ERROR", err)
		yield put(recommendedAsyncActions.error())
	}
}

function* searchVideosSaga(action) {
	try {
		const response = yield call(searchVideos, action.payload.searchTerm, action.payload.query);
		yield put(searchAsyncActions.success(response.data.result))
	}
	catch(err) {
		yield put(searchAsyncActions.error())
	}
}

function* loadMoreSearchVideosSaga(action) {
	try {
		const response = yield call(searchVideos, action.payload.searchTerm, action.payload.query);
		yield put(loadMoreSearchAsyncActions.success(response.data.result))
	}
	catch(err) {
		yield put(loadMoreSearchAsyncActions.error())
	}
}

function* getChannelsSaga(action) {
	try {
		const response = yield call(getChannels);
		yield put(channelListAsyncActions.success(response.data.result))
	}
	catch(err) {
		yield put(channelListAsyncActions.error())
	}
}

function* getTrendingSaga(action) {
	try {
		const response = yield call(trendingVideos);
		yield put(trendingAsyncActions.success(response.data.result))
	}
	catch(err) {
		yield put(trendingAsyncActions.error())
	}
}

function* mainSaga() {
	yield takeLeading(recommendedAsyncActions.actionNames.request, getRecommendedSaga);
	yield takeLeading(channelListAsyncActions.actionNames.request, getChannelsSaga);
	yield takeLeading(trendingAsyncActions.actionNames.request, getTrendingSaga);
	yield takeLatest(searchAsyncActions.actionNames.request, searchVideosSaga);
}

export default mainSaga;