import {call, put, takeLeading, takeLatest} from 'redux-saga/effects';
import {feturedVideosAsyncActions} from './actions';
import {featuredVideos} from './api';
import {toast} from 'react-toastify';

function* featuredVideosSaga(action) {
	try {
		const response = yield call(featuredVideos, action.payload);
		yield put(feturedVideosAsyncActions.success(response.data.result))
	}
	catch(err) {
		yield put(feturedVideosAsyncActions.error())
	}
}

function* mainSaga() {
	yield takeLeading(feturedVideosAsyncActions.actionNames.request, featuredVideosSaga);
}

export default mainSaga;