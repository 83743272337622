import {combineReducers} from 'redux';
import { all } from 'redux-saga/effects'
import summaryReducer, {summarySaga} from './summaries';
import authReducer, {authSaga} from './auth';
import homeReducer, {homeSaga} from './home';
import channelReducer, {channelSaga} from './channel';
import trailerReducer, {trailerSaga} from './trailer';
import embedReducer, {embedSaga} from './embed';
import playerReducer from './player';

export function* rootSaga() {
  yield all([
  	authSaga(),
    summarySaga(),
    homeSaga(),
    channelSaga(),
    trailerSaga(),
    embedSaga(),
  ])
}


export default combineReducers({
	authReducer,
	summaryReducer,
	playerReducer,
	homeReducer,
	channelReducer,
	trailerReducer,
	embedReducer,
})
