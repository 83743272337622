import React from "react";
import YoutubePlayer from "./YoutubePlayer.js";
import VimeoPlayer from "./VimeoPlayer.js";
import WistiaPlayer from "./WistiaPlayer.js";
import { playerActions } from "modules/player";
import { useDispatch } from "react-redux";

export const VideoPlayer = ({
  selectedTimestamp,
  videoId,
  platform,
  addChapter,
  onCopyVideo,
  summaryHeader,
  currentChapterCard,
  themeColor,
  hideChapterSeekerBtn,
  hideChapterOnPlay,
  hideFullscreen,
  trailer,
  trailerObject,
  onPlay,
  onActionButtonClick,
  onVideoStart,
  onVideoEnd,
  videoPaused,
  logo,
  icon,
}) => {
  const dispatch = useDispatch();

  const setCurrentDuration = (seconds) => {
    dispatch(
      playerActions.setCurrentPlayingTimeAction.action(Math.floor(seconds))
    );
  };

  switch (platform) {
    case "vimeo":
      return (
        <VimeoPlayer
          themeColor={themeColor}
          onCopyVideo={onCopyVideo}
          setCurrentDuration={setCurrentDuration}
          addChapter={addChapter}
          videoId={videoId}
          selectedTimestamp={selectedTimestamp}
          summaryHeader={summaryHeader}
          currentChapterCard={currentChapterCard}
          hideChapterSeekerBtn={hideChapterSeekerBtn}
          hideFullscreen={hideFullscreen}
          onVideoStart={onVideoStart}
          onVideoEnd={onVideoEnd}
        />
      );
    case "loom":
      return (
        <div>
          <iframe
            title="loom-player"
            src={`https://www.loom.com/embed/${videoId}?t=${selectedTimestamp.start}`}
            frameBorder="0"
            webkitallowfullscree="true"
            mozallowfullscreen="true"
            allowFullScreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
            }}
          ></iframe>
        </div>
      );
    case "wistia":
      return (
        <WistiaPlayer
          onActionButtonClick={onActionButtonClick}
          onPlay={onPlay}
          themeColor={themeColor}
          onCopyVideo={onCopyVideo}
          setCurrentDuration={setCurrentDuration}
          addChapter={addChapter}
          videoId={videoId}
          selectedTimestamp={selectedTimestamp}
          summaryHeader={summaryHeader}
          currentChapterCard={currentChapterCard}
          onVideoStart={onVideoStart}
          onVideoEnd={onVideoEnd}
          videoPaused={videoPaused}
          hideChapterSeekerBtn={hideChapterSeekerBtn}
          hideFullscreen={hideFullscreen}
          hideChapterOnPlay={hideChapterOnPlay}
          logo={logo}
          icon={icon}
        />
      );
    default:
      return (
        <YoutubePlayer
          trailerObject={trailerObject}
          onActionButtonClick={onActionButtonClick}
          onPlay={onPlay}
          themeColor={themeColor}
          onCopyVideo={onCopyVideo}
          setCurrentDuration={setCurrentDuration}
          addChapter={addChapter}
          videoId={videoId}
          selectedTimestamp={selectedTimestamp}
          summaryHeader={summaryHeader}
          currentChapterCard={currentChapterCard}
          onVideoStart={onVideoStart}
          onVideoEnd={onVideoEnd}
          videoPaused={videoPaused}
          hideChapterSeekerBtn={hideChapterSeekerBtn}
          hideFullscreen={hideFullscreen}
          trailer={trailer}
          hideChapterOnPlay={hideChapterOnPlay}
          logo={logo}
          icon={icon}
        />
      );
  }
};
