import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  RootContainer,
  SummaryContainer,
  VideoContainer,
  SummaryContainerHeader,
  SummaryContainerText,
  SummaryListContainer,
  SearchContainer,
  SearchIcon,
  SearchInput,
  NoSummariesContainer,
  NoSummariesText,
  VideoTitleContainer,
  VideoTitleText,
  VideoTitleHover,
  CopyContentContainer,
  CopyIconContainer,
  CopyTextContainer,
  CopyTextHeading,
} from "../PlayerScreen.style.js";
import {
  AbsoluteContainer,
  GenerateEmailButton,
  GenerateEmailForm,
  EmailSummaryInput,
  EmailSummaryContainer,
} from "./Garage.styles.js";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Summary from "./SummaryCard";
import { VideoPlayer } from "../../components/VideoPlayer.js";
import { LoadingOverlay, Modal } from "components";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { summaryActions } from "modules/summaries";
import { embedActions } from "modules/embed";
import { useParams } from "react-router-dom";
import { BiLink } from "react-icons/bi";
import { BiCopy } from "react-icons/bi";
import { HiCode } from "react-icons/hi";
import copy from "clipboard-copy";
import { toast } from "react-toastify";
import { hashGenerator } from "utils";
import { set } from "animejs";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default () => {
  const dispatch = useDispatch();
  // const [videoTitle, setVideoTitle] = useState('');
  // const [previousState, setPreviousState] = useState(false);
  // const [searchText, setSearchedText] = useState('');
  const [showGenerateEmail, setShowGenerateEmail] = useState(false);
  const [emailMessage, setEmailMessage] = useState("");
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [filteredSummaries, setFilteredSummaries] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoStarted, setVideoStarted] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const summaryHeader = useRef(0);
  const currentChapterCard = useRef(0);
  const summaryObject = useSelector(
    (state) => state.summaryReducer,
    shallowEqual
  );
  const playerState = useSelector((state) => state.playerReducer, shallowEqual);
  const { sessionId } = useSelector(
    (state) => state.embedReducer,
    shallowEqual
  );
  // const history ();
  const { videoId } = useParams();
  const query = useQuery();
  const themeColor = query.get("themeColor");
  const startTime = query.get("startTime");
  const showChapters = query.get("showChapters") || "true";

  let searchText = "";

  const onSearch = (text) => {
    // setSearchedText(text);
    const searchResults = summaryObject.summaries.filter((summary) => {
      let toInclude = false;

      if (summary.type && summary.type === "INSERT") {
        toInclude = true;
      }

      // if(summary.subpoints) {
      // 	const subpointArray = summary.subpoints.map(subpoint => subpoint.title.toLowerCase().includes(text.toLowerCase()))
      // 	toInclude = subpointArray.some(res => res === true)
      // }

      if (
        summary.title &&
        summary.title.toLowerCase().includes(text.toLowerCase())
      ) {
        toInclude = true;
      }

      if (
        summary.summary &&
        summary.summary.toLowerCase().includes(text.toLowerCase())
      ) {
        toInclude = true;
      }

      if (
        summary.description &&
        summary.description.toLowerCase().includes(text.toLowerCase())
      ) {
        toInclude = true;
      }
      return toInclude;
    });
    setFilteredSummaries(searchResults);
  };

  const onCopyVideo = (showShareDialog) => {
    if (showShareDialog) {
      // console.log('onCopyVideo', showShareDialog)
      setShowShareDialog(true);
      return;
    }
    dispatch(summaryActions.shareVideoAsyncAction.request({ videoId }));
    copy(`${summaryObject?.videoTitle}
${moment
  .duration(
    summaryObject?.summaries[summaryObject?.summaries?.length - 1].start * 1000
  )
  .format(
    `${
      summaryObject?.summaries[summaryObject?.summaries?.length - 1].start > 60
        ? "hh:mm:ss"
        : "mm:ss"
    }`,
    {
      trim:
        summaryObject?.summaries[summaryObject?.summaries?.length - 1].start >
        60
          ? true
          : false,
    }
  )} | ${summaryObject?.summaries?.length} chapters 🔖
https://api.chapterme.co/share/video/${videoId}${
      themeColor ? `?themeColor=${themeColor}` : ""
    }`);
    toast.success("Video link copied", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onCopyText = (summary) => {
    const text = `${moment
      .duration(
        summaryObject?.summaries[summaryObject.summary.length - 1].start * 1000
      )
      .format(
        `${
          summaryObject?.summaries[summaryObject?.summaries?.length - 1].start >
          60
            ? "hh:mm:ss"
            : "mm:ss"
        }`,
        {
          trim:
            summaryObject?.summaries[summaryObject?.summaries?.length - 1]
              .start > 60
              ? true
              : false,
        }
      )} - ${summary.title}: ${summary.description}`;
    copy(text);
  };

  useEffect(() => {
    document.title = summaryObject.videoTitle || "Untitled Video";
    document.title = document.title + " | ChapterMe";
    setFilteredSummaries(summaryObject.summaries);
    const copyContent = `Hey John,

Nice speaking with you today. Here is a quick recap of our call.
${summaryObject?.summaries
  ?.map(
    (summary) => `
${moment.duration(summary.start * 1000).format("mm:ss", { trim: false })} - ${
      summary.title || summary.summary
    }`
  )
  .join("")}

Best,
Priyanka`;
    setEmailMessage(copyContent);
  }, [summaryObject]);

  useEffect(() => {
    dispatch(summaryActions.fetchSummariesAsyncActions.request(videoId));
    dispatch(
      embedActions.createSessionAsyncActions.request({
        videoId,
        startTime: startTime ? startTime : 0,
      })
    );
  }, [videoId]);

  useEffect(() => {
    // console.log("CURRENT PLAYING TIME", playerState.currentPlayingTime)
    if (sessionId && playerState.currentPlayingTime % 5 === 0)
      dispatch(
        embedActions.endTimeUpdateAsyncActions.request({
          endTime: playerState.currentPlayingTime,
          sessionId,
        })
      );
  }, [playerState.currentPlayingTime]);

  const onVideoStart = () => {
    if (!videoStarted) {
      setVideoStarted(true);
    }
  };

  const onVideoEnd = () => {
    // console.log("VIDEO ENDED")
    dispatch(embedActions.videoEndedAsyncActions.request());
  };

  const onCopyEmail = () => {
    copy(
      `EyeRate Demo 
15:13 | 12 chapters 🔖 
https://app.chapterme.co/embed/video/fdNaPpp7FGA ` +
        "\n\n" +
        emailMessage
    );
    toast.success("Content copied!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // console.log(summaryObject.logo);

  return (
    <RootContainer id="root-container">
      <AbsoluteContainer id="hello_all">
        {summaryObject.channel === "demogenie" ? (
          showGenerateEmail ? (
            <GenerateEmailForm>
              <EmailSummaryInput
                value={emailMessage}
                onChange={(evt) => setEmailMessage(evt.target.value)}
              />
              <EmailSummaryContainer>
                <div onClick={onCopyEmail}>
                  {" "}
                  <BiCopy color="#fff" size={15} /> Copy
                </div>
                <div onClick={() => setShowGenerateEmail(false)}>Close</div>
              </EmailSummaryContainer>
            </GenerateEmailForm>
          ) : (
            <GenerateEmailButton onClick={() => setShowGenerateEmail(true)}>
              Generate Email
            </GenerateEmailButton>
          )
        ) : null}
      </AbsoluteContainer>
      <VideoContainer>
        {useMemo(
          () => (
            <VideoPlayer
              themeColor={themeColor}
              selectedTimestamp={{
                start: currentTime,
                startTime: startTime ? startTime : 0,
              }}
              videoId={videoId}
              platform={summaryObject.platform}
              onCopyVideo={onCopyVideo}
              summaryHeader={summaryHeader}
              currentChapterCard={currentChapterCard}
              onVideoStart={onVideoStart}
              onVideoEnd={onVideoEnd}
              videoPaused={() => null}
              logo={summaryObject.logo}
              icon={summaryObject && summaryObject.icon}
            />
          ),
          [summaryObject.platform, currentTime]
        )}
      </VideoContainer>
      {playerState.showSummaries && showChapters === "true" ? (
        <SummaryContainer>
          <SummaryContainerHeader
            ref={summaryHeader}
            id="summary-header"
            background={themeColor}
          >
            <SummaryContainerText>Chapters</SummaryContainerText>
            <SearchContainer>
              {showSearch ? (
                <SearchInput onChange={(evt) => onSearch(evt.target.value)} />
              ) : null}
              <SearchIcon
                className="select-nothing"
                onClick={() => {
                  if (showSearch) searchText = "";
                  setShowSearch(!showSearch);
                }}
              />
            </SearchContainer>
          </SummaryContainerHeader>
          <VideoTitleContainer id="videoTitle-container">
            <VideoTitleContainer>
              <VideoTitleText>
                <p>{summaryObject.videoTitle}</p>
              </VideoTitleText>
              <VideoTitleHover>{summaryObject.videoTitle}</VideoTitleHover>
            </VideoTitleContainer>
          </VideoTitleContainer>
          <SummaryListContainer id="garage-summarylistcontainer">
            {filteredSummaries ? (
              filteredSummaries.length ? (
                filteredSummaries.map((summary, index, summaryArray) => (
                  <Summary
                    key={`summarys${index}`}
                    themeColor={themeColor}
                    index={index}
                    summaryArray={summaryArray}
                    videoId={videoId}
                    summary={summary}
                    currentTime={currentTime}
                    setCurrentTime={setCurrentTime}
                    onCopyLink={onCopyText}
                    currentChapterCard={currentChapterCard}
                  />
                ))
              ) : (
                <NoSummariesContainer>
                  <NoSummariesText>
                    This video is not summarised yet
                  </NoSummariesText>
                </NoSummariesContainer>
              )
            ) : (
              <NoSummariesContainer>
                <NoSummariesText>
                  This video is not summarised yet
                </NoSummariesText>
              </NoSummariesContainer>
            )}
          </SummaryListContainer>
        </SummaryContainer>
      ) : null}
      {summaryObject.isLoading ? <LoadingOverlay /> : null}
      {showShareDialog ? (
        <Modal close={() => setShowShareDialog(false)}>
          <NoSummariesContainer>
            <CopyContentContainer onClick={() => onCopyVideo(false)}>
              <CopyIconContainer>
                <BiLink color="#7289da" size={36} />
              </CopyIconContainer>
              <CopyTextContainer>
                <CopyTextHeading>Copy Link</CopyTextHeading>
                {/*<CopyTextContent>Sharable link for the video</CopyTextContent>*/}
              </CopyTextContainer>
            </CopyContentContainer>
            <CopyContentContainer
              className="embed-btn"
              onClick={() => window.open(`/embedSettings/${videoId}`)}
            >
              <CopyIconContainer>
                <HiCode color="#7289da" size={36} />
              </CopyIconContainer>
              <CopyTextContainer>
                <CopyTextHeading>Customize &amp; Embed</CopyTextHeading>
                {/*<CopyTextContent>Embed the current video with our player in any site</CopyTextContent>*/}
              </CopyTextContainer>
            </CopyContentContainer>
          </NoSummariesContainer>
        </Modal>
      ) : null}
    </RootContainer>
  );
};
