import styled from 'styled-components';
import { GrView } from "react-icons/gr";

export const RootContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	background: #000;
	height: 100vh;

	@media only screen and (orientation:portrait) {
		flex-direction: column;
	}
`;

export const VideoContainer = styled.div`
	flex: 3;
	background: #000;
	position: relative;

	@media only screen and (orientation:portrait) {
		flex: 1;
		margin-bottom: 5px;
	}
`;

export const SummaryContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	background: ${({theme}) => theme.darkGrey};
	text-align: left;
	min-height: 0;
	min-width: 0;

	@media only screen and (orientation:portrait) {
		flex: 3;
	}

	@media only screen and (max-width: 850px) {
		flex: 1.5;
	}

	@media only screen and (max-width: 500px) and (orientation:landscape) {
		position: absolute;
		right: 0;
		z-index: 2;
		width: 35%;
		height: 100vh;
	}
`;

export const SearchContainer = styled.div`
	background: ${({theme}) => theme.lightGrey};
	padding: 5px 10px;
	display: flex;
	justify-content: center;
`;

export const SearchInput = styled.input.attrs({placeholder: "Search chapters title and description"})`
	width: 100%;
	padding: 5px 10px;
	color: ${({theme}) => theme.darkGrey};
	font-weight: bold;
	border: none;
	outline: none;
	border-radius: 5px;
`;

export const SummaryListContainer = styled.div`
	flex: 1;
	height: 100%;
	scrollbar-width: none;
	&:-webkit-scrollbar {
		display: none;
	}
`;

export const SummaryContainerHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: ${props => props.background ? `#${props.background}` : props.theme.purple};
	padding: 10px;
`

export const SummaryContainerText = styled.div`
	color: ${({theme}) => theme.white};
	font-weight: bolder;
	@media only screen and (max-width: 650px)
	{
		font-size: 18px;	
	}
	@media only screen and (max-width: 500px)
	{
		font-size: 14px;
	}
`;;

export const SummaryCard = styled.div`
	border-left: ${(props) => props.focused?'10px':'5px'} solid ${(props) => props.focused?`#${props.border}`:props.theme.metallic};
	padding: 5px;
	padding-left: 10px;
	color: ${({theme}) => theme.white};
	border-bottom: 1px solid ${(props) => props.theme.lightGrey};
	transition: all 0.2s ease;
	cursor: pointer;
	@media only screen and (max-width: 550px)
	{
		border-left: ${(props) => props.focused?'6px':'4px'} solid ${(props) => props.focused?`#${props.border}`:props.theme.metallic};
	}
`;

export const SummaryTitle = styled.div`
	font-size: 20px;
	margin-bottom: 5px;
	wordBreak: break-all;

	@media only screen and (max-width: 750px)
	{
		font-size: 18px;
	}
	@media only screen and (max-width: 650px)
	{
		font-size: 16px;	
	}
	@media only screen and (max-width: 500px)
	{
		font-size: 14px;
	}

`;

export const SummaryDescription = styled.div`
	font-size: 14px;
	color: ${({theme}) => theme.metallic};
`;

export const SummaryCardTopBar = styled.div`
	display: flex;
	color: ${({theme}) => theme.metallic};
	align-items: center;
	text-align: left;
`;

export const SummaryCardTopBarLeftPiece = styled.div`
	flex: 1;
	font-size: 14px;
	color: ${({theme}) => theme.metallic};
`;

export const SummaryCardTopBarRightPiece = styled.div`
	flex: 1;
	display: flex;
	justify-content: flex-end;
	font-size: 12px;
	font-weight: bold;
	color: ${({theme}) => theme.metallic};
`;

export const ViewIcon = styled(GrView)`
	color: ${({theme}) => theme.metallic}
`;

export const SummaryCardButtonBar = styled.div`
	flex: 1;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 10px;
`;

export const SummaryCardButtonContainer = styled.div`
	cursor: pointer;
`;

export const CopyContentContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 10px;
	margin: 10px;
	border-radius: 5px;
	border: 1px solid ${({theme}) => theme.metallic};
	cursor: pointer;
`;

export const CopyIconContainer = styled.div`
	border-radius: 50%;
	padding: 10px;
`;

export const CopyTextContainer = styled.div`
	flex: 1;
	padding: 10px;
`;

export const CopyTextHeading = styled.div`
	font-size: 24px;
	font-weight: bold;
	color: ${({theme}) => theme.white};
`;

export const CopyTextContent = styled.div`
	font-size: 18px;
	color: ${({theme}) => theme.metallic};
`;

export const FullVideoButtonContainer = styled.div`
	display: flex;
	justify-content: center
`;

export const FullVideo = styled.div`
	display: inline-block;
	padding: 10px;
	margin: 10px;
	border-radius: 5px;
	background: ${({theme}) => theme.purple};
	color: ${({theme}) => theme.white};
	font-weight: bold;
	cursor: pointer;
	transition: all 0.3s ease;
	align-self: center;
	text-align: center;

	&:hover {
		color: ${({theme}) => theme.purple};
		background: ${({theme}) => theme.white};
	}

	&:active {
		transform: scale(0.8);
	}


	@media only screen and (max-width: 650px)
	{
		font-size: 14px;
	}
`;