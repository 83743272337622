import styled from 'styled-components';

export const RootContainer = styled.div`
	width: 100%;
	height: 100vh;
	margin: 0;
	padding: 0;
`;

export const Jumbotron = styled.div`
	background: ${({theme}) => theme.purple};
	width: 100%;
	min-height: 40vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;

	@media only screen and (max-width: 700px) {
		// height: auto;
		padding-top: 10px;
	}
`;

export const TitleImage = styled.img`
	width: 300px;
	height: auto;

	@media only screen and (max-width: 700px) {
		width: 60%;
		margin: 5px;
	}

	& + span {
		padding: 16px 0 0 0;
		margin: 16px;
		margin-bottom: 0;
		font-size: 1.2em;
		color: white;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}

	& + span {
		color: white;
	}

	& + span div {
		font-size: 0.8em;
		text-align: center;
		background: #262A2D;
		padding: 10px;
		margin: 5px;
		margin-top: 16px;
		border-radius: 5px;
		border: 1px solid #262A2D;
		cursor: pointer;
		opacity: .8;
		transition: opacity 300ms;
	}

	& + span div:hover {
		opacity: 1;
	}

	& + span div:active {
		opacity: .8;
	}
`;

export const SearchButton = styled.div`
	margin: 10px;
	padding: 10px;
	display: none;
	border-radius: 25px;
	background: ${({theme}) => theme.white};
	color: ${({theme}) => theme.purple};
	cursor: pointer;
	transition: all 0.1s ease;

	&:hover {
		transform: scale(1.05);
	}

	&:active {
		transform: scale(0.9);
	}

	// @media only screen and (max-width: 700px) {
	// 	display: flex;
	// 	align-items: center;
	// }
`;

export const SearchInputContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 20px;
	width: 60%;
	border: none;
	outline: none;
	border-radius: 5px;
	background: ${props => props.theme.white};
	font-weight: bold;
	color: ${({theme}) => theme.darkGrey};

	@media only screen and (max-width: 700px) {
		width: 90%;
	}
`; 

export const SearchBarContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 15px;
`;

export const SearchInput = styled.input.attrs({type: 'text', placeholder: "Search by video name, description, chapters, or channel name"})`
	flex: 1;
	border: none;
	outline: none;
	border-radius: 5px;
	background: ${props => props.theme.white};
	font-weight: bold;
	color: ${({theme}) => theme.darkGrey};
`;

export const SearchResultScrollable = styled.div`
	position: absolute;
	background: ${props => props.theme.white};
	top: 40px;
	width: 100%;
	max-height: 40vh;
	flex: 1;
	overflow-y: scroll;
`;

export const SearchResultContainer = styled.div`
	display: flex;
	margin: 5px;
	cursor: pointer;
`;

// export const YoutubeThumbnail = styled.img`
// 	width: 100px;
// 	height: 76px;
// `;

export const SearchResult = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 5px;
`;

export const SearchVideoTitle = styled.div`
	margin: 5px 10px;
	font-size: 1em;
	font-weight: bold;
	color: ${({theme}) => theme.darkGrey};
`;

export const SearchVideoSubtitle = styled.div`
	margin: 5px 10px;
	font-size: 1em;
	font-weight: normal;
	color: ${({theme}) => theme.darkGrey};
`;

export const RecommendationContainer = styled.div`
	background: ${props => props.theme.white};
	padding: 20px 10px;
`;

export const RecommendedSection = styled.div`
	display: flex;
	flex-direction: column;
	background: ${({theme}) => theme.halfWhite};
	padding: 10px;
	border-radius: 20px;
	box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.25);
`;

export const RecommendedSectionTitle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: ${({theme}) => theme.purple};
	font-weight: bold;
	font-size: 18px;
	margin: 10px;
	text-align: left;
`;

export const RecommendedSectionViewMore = styled.div`
	text-align: center;
	// color: ${({theme}) => theme.purple};
	color: white;
	font-weight: bold;
	font-size: 13px;
	// text-decoration: underline;
	margin: 10px;
	padding: 10px;
	cursor: pointer;
	background: ${({theme}) => theme.purple};
	border-radius: 3px;
	transition: opacity 300ms;

	&:hover {
		opacity: .8;
		border-radius: 2px;
	}
`;

export const RecommendedSectionScrollable = styled.div`
	display: flex;
	overflow-y: hidden;
`;

export const RecommendationLoadingContainer = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	padding: 30px;
`;

export const RecommendedSlide = styled.div`
	border-radius: 10px;
	margin: 10px;
	background: #CCC;
	cursor: pointer;
`;

// export const RecommendedSlideImage = styled.img`
// 	width: 240px;
// 	height: 180px;
// `;

export const RecommendedSlideTitle = styled.div`
	margin: 10px;
	color: ${({theme}) => theme.purple};
	font-size: 14px;
	font-weight: bold;
	text-align: left;
`;

export const RecommendedSlideSubtitle = styled.div`
	margin: 5px 10px;
	text-align: left;
	color: ${({theme}) => theme.darkGrey};
	font-size: 12px;
`;

export const ChannelImageContainer = styled.div`
	width: 240px;
	height: 180px;
	background: ${props => props.background?props.background:props.theme.darkGrey};
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
`;

export const ChannelImage = styled.img`
	width: 200px;
	height: auto;
`;