import { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';

import defaultVimeoThumb  from '../../vimeo.jpg';
import defaultWistiaThumb from '../../wistia-logo.jpg';

const YoutubeThumbnail = styled.img`
	width: 100px;
	height: 76px;
`;

const RecommendedSlideImage = styled.img`
	width: 240px;
	height: 180px;
`;

const ThumbImage = styled.img`
	width: 100%;
	height: auto;
	background-color: #333;
	background-image: url('https://chapterme.co/assets/img/logo-white.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
`;

// const thumbUrlGenerator = (platform, videoId) => {
// 	switch(platform) {
// 		case 'vimeo':
// 			return `https://i.vimeocdn.com/video/${videoId}_200x150`;
// 		default:
// 			return `https://img.youtube.com/vi/${videoId}/0.jpg`
// 	}
// }

const Thumbnail = ({video, fromSearch, fromPreview}) => {

	const [url, setUrl] = useState('');

	useEffect(() => {
		let url_tmp;
		if (video.platform === 'wistia') {
			axios.get(`https://fast.wistia.com/oembed?url=http%3A%2F%2Fhome.wistia.com%2Fmedias%2F${video.videoId}`)
				.then(res => {
					// console.log(res.data.thumbnail_url);
					url_tmp = res.data.thumbnail_url.replace('image_crop_resized=960x540', 'image_crop_resized=480x360');
					setUrl(url_tmp);
				})
				.catch(err => {
					// console.log('unable to get image')
					setUrl(defaultWistiaThumb);
					})
		}
		else if (video.platform === 'vimeo')
		{
			axios.get(`https://vimeo.com/api/v2/video/${video.videoId}.json`)
				.then(res => {
					url_tmp = res.data[0].thumbnail_medium; 
					setUrl(url_tmp);
					// console.log(res.data[0].thumbnail_medium);
				})
				.catch(err => {
					url_tmp = defaultVimeoThumb;
					setUrl(url_tmp);
					// console.log('fetching error:', err);
				});
		}
		else {
			url_tmp = `https://img.youtube.com/vi/${video.videoId}/0.jpg`;
			setUrl(url_tmp);
		}

	}, []);

	if (fromSearch)
		return (
			<YoutubeThumbnail src={url} alt='' loading="lazy" />
		);
	else if (fromPreview)
		return (
			<ThumbImage src={url} alt='' loading="lazy" />
		);
	else
		return (
			<RecommendedSlideImage src={url} alt='' loading="lazy" draggable="false" dragstart="false" />
		);
}

export default Thumbnail;