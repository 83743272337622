import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';

const GlassyCover = styled.div`
	position: absolute;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background: rgba(0,0,0,0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99;
`;
const Circle = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.1rem;
	height: 130px;
	width: 130px;
	color: ${({theme}) => theme.white};
	border-radius: 50%;
	border: none;
	background: ${({theme}) => theme.purple};
`;

const LoadingOverlay = () => {
	return (
		<GlassyCover>
			<ClipLoader color='#7289da' loading={true} size={150} />
			<Circle>Loading...</Circle>
		</GlassyCover>
	)
}

export default LoadingOverlay;