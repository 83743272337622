import {
	createSessionAsyncActions,
	videoEndedAsyncActions,
	endTimeUpdateAsyncActions,
} from './actions';

const initialState = {
	isLoading: false,
	isError: false,
	sessionId: '',
	session: {}
}

export default (state = initialState, action) => {
	switch(action.type) {
		case createSessionAsyncActions.actionNames.request: 
			return {...state, ...initialState, isLoading: true}

		case createSessionAsyncActions.actionNames.success: 
			return {...state, sessionId: action.payload._id, isLoading: false}

		case createSessionAsyncActions.actionNames.error: 
			return {...state, isLoading: false}

		case videoEndedAsyncActions.actionNames.request: 
			return {...state, isLoading: true}

		case videoEndedAsyncActions.actionNames.success: 
			return {...state, session: action.payload, isLoading: false}

		case videoEndedAsyncActions.actionNames.error: 
			return {...state, isLoading: false}

		case endTimeUpdateAsyncActions.actionNames.request: 
			return {...state, isLoading: true}

		case endTimeUpdateAsyncActions.actionNames.success: 
			return {...state, session: action.payload, isLoading: false}

		case endTimeUpdateAsyncActions.actionNames.error: 
			return {...state, isLoading: false}

		default:
			return state;
	}
}