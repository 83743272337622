import axios from 'axios';

export const createSession = ({videoId, startTime}) => {
	return axios.post("/session/video/create", {videoId, startTime})
}

export const endTimeUpdate = ({sessionId, endTime}) => {
	return axios.post("/session/video/endTime", {sessionId, endTime})
}

export const videoEnded = (data) => {
	// console.log("SESSION API CALL", data)
	return axios.post("/session/video/videoEnded", {sessionId: data})
}