import React, {useState, useEffect, useMemo} from 'react';
import {
	RootContainer,
	SummaryContainer,
	VideoContainer,
	VideoTitleContainer,
	VideoTitleText,
	VideoTitleHover,
	// SummaryContainerHeader,
	// SummaryContainerText,
	SummaryListContainer,
	FullVideo,
	FullVideoButtonContainer
} from '../PlayerScreen.style.js'
import {useLocation} from 'react-router-dom';
import Summary from './SummaryCard';
import {VideoPlayer} from '../../components/VideoPlayer.js';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {summaryActions} from 'modules/summaries';
import { useParams } from "react-router-dom";
import copy from 'clipboard-copy';
import {toast} from 'react-toastify';
import moment from 'moment';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default () => {
	const dispatch = useDispatch()
	// const [searchText, setSearchedText] = useState('');
	// const [filteredSummaries, setFilteredSummaries] = useState([]);
	const [chapterIndex, setChapterIndex] = useState()
	const [currentTime, setCurrentTime] = useState(0);
	// const [randomNumber, setRandomNumber] = useState(0);
	const summaryObject = useSelector(state => state.summaryReducer, shallowEqual)
	const playerState = useSelector(state => state.playerReducer, shallowEqual)
	const query = useQuery();
	const themeColor = query.get('themeColor');
	const { videoId, start } = useParams();

	const onCopyVideo = () => {
		copy(`${summaryObject.videoTitle}
${moment.duration(summaryObject.summaries[summaryObject.summaries.length-1].start*1000).format('hh:mm:ss')} | ${summaryObject.summaries.length} chapters 🔖
https://api.chapterme.co/share/video/${videoId}`)
		toast.success('Video link copied', {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}

	useEffect(() => {
		document.title = summaryObject.videoTitle || 'Loading';
		const cptIndex = summaryObject.summaries.findIndex(summary => summary.start === parseInt(start))
		setChapterIndex(cptIndex);
		if (summaryObject && summaryObject.summaries && summaryObject.summaries.length && cptIndex !== -1) {
			setCurrentTime(summaryObject.summaries[cptIndex].start);
		}
	}, [summaryObject])

	useEffect(() => {
		dispatch(summaryActions.fetchSummariesAsyncActions.request(videoId));
	}, [videoId]);

	return (
		<RootContainer id='root-container' >
			<VideoContainer>
				{
					useMemo(() => <VideoPlayer themeColor={themeColor} hideChapterOnPlay={true} selectedTimestamp={{start: currentTime}} videoId={videoId} platform={summaryObject.platform} onCopyVideo={onCopyVideo} hideChapterSeekerBtn={true} />, [currentTime]) 
				}
			</VideoContainer>
			{
				playerState.showSummaries
				?
					<SummaryContainer>
						{/*<SummaryContainerHeader background={themeColor} >
							<SummaryContainerText>CHAPTERS</SummaryContainerText>
						</SummaryContainerHeader>*/}
						<VideoTitleContainer id='videoTitle-container'>
							<VideoTitleContainer>
								<VideoTitleText><p>{summaryObject.videoTitle}</p></VideoTitleText>
								<VideoTitleHover>{summaryObject.videoTitle}</VideoTitleHover>
							</VideoTitleContainer>
						</VideoTitleContainer>
						<SummaryListContainer>
							{
								summaryObject && summaryObject.summaries && summaryObject.summaries.length && chapterIndex !== -1
								?
								<Summary themeColor={themeColor} summary={summaryObject.summaries[chapterIndex]} index={chapterIndex} currentTime={currentTime} setCurrentTime={setCurrentTime} videoId={videoId} />
								:
								null
							}
							<FullVideoButtonContainer>
								<FullVideo onClick={() => window.open(`/player/video/${videoId}`)} >
									Watch Full Video
								</FullVideo>
							</FullVideoButtonContainer>
					</SummaryListContainer>
				</SummaryContainer>
					:null
			}
		</RootContainer>
	)
}
