import axios from "axios";

// const instance = axios.create({
//   baseURL: "https://api.chapterme.co",
//   // baseURL: "http://localhost:8000",
// });

export const fetchSummaries = (videoId) => {
  return axios.get("/video/summary/" + videoId);
};

export const fetchEmbedSummaries = (videoId) => {
  return axios.get("/video/summary/embed/" + videoId);
};

export const upsertSummaries = (videoId, body) => {
  return axios.post("/video/summary/" + videoId, body);
};

export const shareVideo = (videoId) => {
  return axios.patch(`/analytics/share/summary/video/${videoId}`);
};

export const shareChapter = (videoId, startTime) => {
  return axios.patch(
    `/analytics/share/summary/chapter?id=${videoId}&start=${startTime}`
  );
};

export const shareEmbedChapter = (videoId, startTime) => {
  return axios.patch(
    `/analytics/share/embed/summary/chapter?id=${videoId}&start=${startTime}`
  );
};

export const viewChapter = (videoId, startTime) => {
  return axios.patch(
    `/analytics/view/summary/chapter?id=${videoId}&start=${startTime}`
  );
};

export const viewEmbedChapter = (videoId, startTime) => {
  return axios.patch(
    `/analytics/view/embed/summary/chapter?id=${videoId}&start=${startTime}`
  );
};

export const customisedEmbed = (videoId) => {
  return axios.patch(`/analytics/embed/summary/customise/${videoId}`);
};

export const embedButton = (videoId) => {
  return axios.patch(`/analytics/embed/summary/iframe/${videoId}`);
};
