import React, { useState, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import {VideoPlayer} from '../../components/VideoPlayer.js';
import {LoadingOverlay} from 'components';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {trailerActions} from 'modules/trailer';
import { useParams, useLocation } from "react-router-dom";
import socketIOClient from 'socket.io-client';

const RootContainer = styled.div`
	height: 100vh;
	width: 100vw;
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default () => {
	const dispatch = useDispatch()
	const trailerObject = useSelector(state => state.trailerReducer, shallowEqual)
	const playerState = useSelector(state => state.playerReducer, shallowEqual)
	const [currentTime, setCurrentTime] = useState(0);
	const { videoId } = useParams();
	const query = useQuery();
	const themeColor = query.get('themeColor');
	const trailerId = query.get('trailerId');
	const sessionId = query.get('sessionId');
	// const socketRef = useRef();
	// const SOCKET_SERVER_URL = "https://api.chapterme.co/";
	// const SOCKET_SERVER_URL = "http://localhost:5000/";

	const onPlay = () => {
		// dispatch(trailerActions.playTrailerAsyncActions.request({trailerId: trailerObject.trailerId, videoId}))
	}

	const onActionButtonClick = (location, actionButtonTimestamp) => {
		// console.log("ACTION BUTTON CLICK", location, actionButtonTimestamp)
		dispatch(trailerActions.actionButtonAsyncActions.request({sessionId, actionButtonLocation: location, actionButtonTimestamp}))
	}

  useEffect(() => {
  	setCurrentTime(playerState.currentPlayingTime)

  	if(playerState.currentPlayingTime%5 === 0)
  		dispatch(trailerActions.endTimeUpdateAsyncActions.request({sessionId, videoId, endTime: playerState.currentPlayingTime}))

  }, [playerState.currentPlayingTime])

  const onVideoStart = () => {
	  	
  }

  const videoPaused = () => {
  	
  }

  const onVideoEnd = () => {
	  dispatch(trailerActions.videoEndedAsyncActions.request({sessionId}))
  }

	return (
		<RootContainer id="root-container">
				{
					useMemo(() => <VideoPlayer trailerObject={trailerObject} themeColor={themeColor} trailer={true} videoId={videoId} platform='youtube' onPlay={onPlay} onActionButtonClick={onActionButtonClick} onVideoStart={onVideoStart} onVideoEnd={onVideoEnd} videoPaused={videoPaused}  />, [trailerObject])
				}
				{
					trailerObject.isLoading
					?
					<LoadingOverlay />
					:
					null
				}
		</RootContainer>
	)
}
