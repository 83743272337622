import  React, { useRef, useEffect, useState, useCallback } from 'react';
import {MdRefresh} from 'react-icons/md';
import { BsFullscreen, BsFullscreenExit } from 'react-icons/bs';

import short_logo from '../../chapterme_short_icon.png';

import {toggleFullscreen} from '../overlay/Settings_and_others.js';

import {
	UpperStartingScreen,
	StartingVideoTitle,
	LowerStartingScreen,
	OverlayContainer,
	TransparentScreen,
	VideoInfoContainer,
	VideoTitle,
	VideoDescription,
	PlayJoinBtnContainer,
	Button,
	JoinToMemberShipBtn,
	PauseBtn,
	Seeker,
	BottomNav,
	BottomLogoContainer,
	LogoImage,
	InitialVideoInfoContainer,
	InitialPlayJoinBtnContainer,
	PlayPauseBtnContainer,
	PlayBtn,
	Refresh,
	Mute,
	Fullscreen
} from './overlay.style.js';

let controlsTimeout, gradientTimeout, volumeRememberer, lastClickTime = 1000;

export const Overlay = ({
	currentTime, 
	duration,
	isPlaying,
	play,
	themeColor,
	seekTo,
	trailerObject,
	onPlay,
	onActionButtonClick,
	videoState,
	volume
}) => {

	const [ShowControls, setShowControls] = useState(true);
	const [showGradient, setShowGradient] = useState(null);
	const mute = useRef(0);
	const controls = useRef(0);

	const setGradientTimeout = (time) => {
		clearTimeout(gradientTimeout);
		gradientTimeout = setTimeout(() => {
					setShowGradient(false);
				}, time);
		setShowGradient(true)
	}
	const controlsHider = useCallback(() => {
		// console.log(videoState)
		setShowControls(true);
		clearInterval(controlsTimeout);
		controlsTimeout =  setInterval(() => {
			setShowControls(false);
		}, 2000);
	}, [])

	const showControls = () => {
		setShowControls(true);
		clearTimeout(controlsTimeout);
	}

	const onPlayClick = () => {
		play(true);
		onPlay();
	}

	const onActionButtonClicked = (location) => {
		window.open(trailerObject.actionButtonLink, "_blank");
		onActionButtonClick(location, currentTime);
	}

	const onMute = () => {
		// let mute = document.getElementById('mute'), waves = document.querySelector('#mute span');
		if (volume.volume === 0)
		{
			volume.setVolume(volumeRememberer);
		}
		else
		{
			volumeRememberer = volume.volume;
			volume.setVolume(0);
		}
	}


	useEffect(() => {
		if (isPlaying && controls.current)
		{
			controls.current.style.opacity =  !ShowControls ? 0: 1;
			controls.current.style.visibility = !ShowControls ? 'hidden' : 'visible';
		}
	}, [ShowControls]);

	useEffect(() => {
		if (isPlaying)
		{
			if (videoState === -1 || videoState === 2 || videoState === 3) {
				const timeout = showGradient === null || currentTime === 0 ? 4000 : 2000
				setGradientTimeout(timeout);
			}
			controlsHider();
		}
	}, [ isPlaying, videoState, setShowGradient, currentTime ]);



	return (
		<>
		{
		videoState === -1 && (trailerObject.channel !== 'exponent' && trailerObject.channel !== 'growthchannel' && trailerObject.channel !== 'chapterme')
		?
		<>
		<UpperStartingScreen />
		<StartingVideoTitle>{trailerObject.title}</StartingVideoTitle>
		<LowerStartingScreen />
		</>
		: 
		<OverlayContainer tabIndex={1} >
			<TransparentScreen isPlaying={isPlaying} showGradient={showGradient} videoState={videoState} onMouseMove={() => {
				// if(!window.matchMedia("(any-hover: none)").matches) {
					controlsHider();
					if (currentTime > 4)
					setGradientTimeout(2000);
				// }
			}}
			onClick={e => {
				if(window.matchMedia("(any-hover: none)").matches && isPlaying) {
				    // do sth
				    if (ShowControls)
				    {
				    	if (trailerObject.channel === 'exponent' && currentTime === 0)
				    		onPlayClick();
				    	else if (isPlaying)
				    		play(false);
				    	else
				    		play(true);
				    }
				    else
				    	controlsHider();
				    lastClickTime = e.timeStamp;
				}
				else
				{
					if (trailerObject.channel === 'exponent' && currentTime === 0)
						onPlayClick();
					else if (isPlaying)
						play(false);
					else
						play(true);
				}
				// controlsHider();
			}}
			></TransparentScreen>
				{
					isPlaying
					?
					<div>
						<JoinToMemberShipBtn padding={trailerObject.shortDescription !== ''?'10px':0} >
							<div>
								{trailerObject.shortDescription} 
							</div>
							{
							trailerObject.actionButtonText?
							<Button className="select-nothing" themeColor={trailerObject.actionButtonColor || '#35599c'} color={trailerObject.actionButtonTextColor || findFontColor(trailerObject.actionButtonColor)} onClick={() => onActionButtonClicked("CTA2")} >{trailerObject.actionButtonText}</Button>
							:null
							}
						</JoinToMemberShipBtn>
						<div ref={controls} style={{transition: 'opacity 300ms, visibility 0s'}} >
							{/*<PlayPauseBtnContainer themeColor={trailerObject.actionButtonColor || '#35599c'} onClick={() => {play(false)}}>
								<PauseBtn/>
							</PlayPauseBtnContainer>*/}
							<BottomNav 
								onMouseEnter={showControls}
								onMouseLeave={controlsHider}
								onTouchMove={showControls}
								onTouchEnd={controlsHider}
							>
								<BottomLogoContainer>
									<div style={{display: 'flex', alignItems: 'center'}} >
										<Mute muted={volume.volume===0} ref={el => mute.current = el} onClick={() => {onMute()}} ><span></span></Mute>
										<Fullscreen color='white' size='20px' style={{cursor: 'pointer'}} onClick={toggleFullscreen} />	
									</div>								
									{
									trailerObject.logo
									?
									<LogoImage src={trailerObject.logo} onClick={() => {window.open(trailerObject.url, '_blank');} }/>
									:
									<LogoImage src={window.innerWidth > 800?'https://chapterme.co/assets/img/logo-white.svg':short_logo} onClick={() => {window.open("https://chapterme.co", "_blank"); } }/>
									}
								</BottomLogoContainer>
								<Seeker dur={duration} currentTime={currentTime} themeColor={trailerObject.actionButtonColor || '#ffffff'} onChange={e => {seekTo(e.target.value)}}/>
							</BottomNav>
						</div>
					</div>
					:
					currentTime === 0
					?
					<InitialVideoInfoContainer>
						{
						trailerObject.channel !== 'exponent' || videoState === 0
						?
						<VideoTitle>
							{trailerObject.title}
						</VideoTitle>
						:
						null
						}
						{
						videoState === -1
						?	
						<PlayPauseBtnContainer themeColor={trailerObject.actionButtonColor || '#35599c'} onClick={() => {onPlayClick();}}>
							<PlayBtn/>
						</PlayPauseBtnContainer>
						:
						<PlayJoinBtnContainer justify={trailerObject.channel==='exponent'?'center':'space-between'} >
							{
								trailerObject.actionButtonText ?
									<Button className="select-nothing" themeColor={trailerObject.actionButtonColor || '#35599c'} color={trailerObject.actionButtonTextColor || findFontColor(trailerObject.actionButtonColor)} onClick={() => onActionButtonClicked("CTA1")} >{trailerObject.actionButtonText}</Button>
								:null
							}
							</PlayJoinBtnContainer>
						}
						{
						videoState === 0 && trailerObject.channel !== 'exponent'
						?
						<Refresh onClick={onPlayClick}><MdRefresh color="white" size="18" /><span>Rewatch</span></Refresh>
						:
						null
						}
					</InitialVideoInfoContainer>
					:
					<VideoInfoContainer>
						<VideoTitle from='pauseState' >
							{trailerObject.title}
						</VideoTitle>
						<VideoDescription>
							{trailerObject.description}
						</VideoDescription>
						<div style={{height: '4vh'}} ></div>
						<PlayJoinBtnContainer>
								<Button className="select-nothing" themeColor={trailerObject.actionButtonColor || '#35599c'} color={trailerObject.actionButtonTextColor || findFontColor(trailerObject.actionButtonColor)} onClick={() => play(true)} >Continue {trailerObject.channel === 'exponent'? 'preview':''}</Button>
								{
									trailerObject.actionButtonText?
									<Button className="select-nothing" themeColor={trailerObject.actionButtonColor || '#35599c'} color={trailerObject.actionButtonTextColor || findFontColor(trailerObject.actionButtonColor)} onClick={() => onActionButtonClicked("CTA1")} >{trailerObject.actionButtonText}</Button>
									:null
								}
						</PlayJoinBtnContainer>
					</VideoInfoContainer>
				}
		</OverlayContainer>
		}
		</>
	);
}

const findFontColor = (bgColor) => {

	var brightness, r, g, b, hsp;

	brightness = lightOrDark(bgColor);

	// If the background color is dark, add the light-text class to it
	if(brightness === 'dark') {
		return 'white';
	}
	else {
		return 'black';
	}

	function lightOrDark(color) {

	  // Check the format of the color, HEX or RGB?
	  if (color.match(/^rgb/)) {

	    // If HEX --> store the red, green, blue values in separate variables
	    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

	    r = color[1];
	    g = color[2];
	    b = color[3];
	  } 
	  else {

	    // If RGB --> Convert it to HEX: http://gist.github.com/983661
	    color = +("0x" + color.slice(1).replace( 
	      color.length < 5 && /./g, '$&$&'
	    )
	             );

	    r = color >> 16;
	    g = color >> 8 & 255;
	    b = color & 255;
	  }

	  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
	  hsp = Math.sqrt(
	    0.299 * (r * r) +
	    0.587 * (g * g) +
	    0.114 * (b * b)
	  );

	  // Using the HSP value, determine whether the color is light or dark
	  if (hsp>127.5) {

	    return 'light';
	  } 
	  else {

	    return 'dark';
	  }
	}
}