import {useEffect, useState} from 'react';
import styled from 'styled-components';
import moment from 'moment';

const RootContainer = styled.div`
	display: flex;
	padding: 10px;
	margin: 10px;
	border-radius: 10px;
	background: ${({theme}) => theme.halfWhite};

	@media only screen and (max-width: 820px) {
		flex-direction: column;
		padding: 5px;
	}
`;

const VideoThumbContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-self: center;
	cursor: pointer;
`;

// const ThumbImage = styled.img`
// 	width: 100%;
// 	height: auto;
// `;

const VideoTitle = styled.div`
	padding: 5px;
	font-weight: bold;
	font-size: 16px;
	color: ${({theme}) => theme.purple};
	text-align: center;
`;

const VideoSubtitle = styled.div`
	padding: 5px;
	font-weight: 500;
	font-size: 12px;
	color: ${({theme}) => theme.darkGrey}
`;

const VideoSummaryContainer = styled.div`
	flex: ${props => props.forChannel?'2':'2'};
	margin: 0px 20px;

	@media only screen and (max-width: 820px) {
		margin: 0px 5px;
	}
`;

const VideoSummaryTableContainer = styled.div`
	overflow-y: auto;
	max-height: 250px;
	margin: 10px 0px;
	cursor: pointer;
`;

const SummaryRow = styled.div`
	background: ${({theme}) => theme.white};
	padding: 5px;
	display: flex;
	color: ${({theme}) => theme.darkGrey};
	font-weight: 500; 

	&:nth-child(even) {
		background: ${({theme}) => theme.halfWhite};
	}

	@media only screen and (max-width: 820px) {
		font-size: 12px;
	}
`;

const SummarySerial = styled.div`
	padding: 0px 5px;
`;

const SummaryTitle = styled.div`
	padding: 0px 5px;
	flex: 1;
`;


const PreviewedVideo = ({summary, Thumbnail, onVideoClick, forChannel, fromPreview}) => {

	const [summaryTitleTextLength, setSummaryTitleTextLength] = useState(27);

	const handleSummayTitleText = () => {
		setSummaryTitleTextLength(23);
		try {
			let chapterTitle = document.getElementsByClassName('preview-video-title');
			// console.log(chapterTitle);
			let parentElem = chapterTitle[0].parentElement;
			// console.log(chapterTitle[0])

			let totalTextInChapterTitle = chapterTitle[0].innerText.length;
			let totalTextCanBeDisplayed  = Math.floor((parentElem.clientWidth*totalTextInChapterTitle)/(chapterTitle[0].offsetWidth/2) - 10);
			// console.log(totalTextInChapterTitle, totalTextCanBeDisplayed);

			setSummaryTitleTextLength(totalTextCanBeDisplayed);
			
		} catch(e) {
			// statements
			// console.log(e);
		}
	}

	useEffect(() => {
		handleSummayTitleText()
		window.addEventListener('resize', () => {
			handleSummayTitleText()
		});

		return () => {window.removeEventListener('resize', () => {handleSummayTitleText()})}
	}, [])

	if(!summary)
		return null

	return (
		<RootContainer>
			{
				forChannel
				?
				<VideoThumbContainer onClick={() => {onVideoClick(summary.videoId)}}>
					<Thumbnail video={summary} fromPreview={true} />
					<VideoTitle>{summary.videoTitle.length < summaryTitleTextLength+10 ? summary.videoTitle : summary.videoTitle.substr(0, summary.videoTitle.lastIndexOf(' ', summaryTitleTextLength+10))+'...'}</VideoTitle>
					<VideoSubtitle>{moment.duration(summary.summary[summary.summary.length-1].start*1000).format('hh:mm:ss')} | {summary.summary.length} Chapters</VideoSubtitle>
				</VideoThumbContainer>
				:
				<VideoThumbContainer onClick={() => {onVideoClick(summary.videoId)}}>
					<Thumbnail video={summary} fromPreview={fromPreview} />
				</VideoThumbContainer>
			}
			<VideoSummaryContainer forChannel={forChannel} >
				{
					forChannel
					?
					null
					:
					<VideoThumbContainer onClick={() => {onVideoClick(summary.videoId)}} >
						<VideoTitle>{summary.videoTitle.length < summaryTitleTextLength+10 ? summary.videoTitle : summary.videoTitle.substr(0, summary.videoTitle.lastIndexOf(' ', summaryTitleTextLength+10))+'...'}</VideoTitle>
						<VideoSubtitle>{moment.duration(summary.summary[summary.summary.length-1].start*1000).format('hh:mm:ss')} | {summary.summary.length} Chapters</VideoSubtitle>
					</VideoThumbContainer>
				}
				<VideoSummaryTableContainer>
					{
						summary.summary
						?
						summary.summary.map((summaryObj, index) => {
							if(!summaryObj.summary && !summaryObj.title){
								return;
							}
							return (
								<SummaryRow key={`preview${index+''+summary.start}`} onClick={() => {onVideoClick(summary.videoId, summaryObj.start)}}>
									<SummarySerial>{index+1}.</SummarySerial>
									<SummaryTitle><span className='preview-video-title' >{
										summaryObj.title
										?
											summaryObj.title.length < summaryTitleTextLength 
											? 
												summaryObj.title 
											: summaryObj.title.substr(0, summaryObj.title.lastIndexOf(' ', summaryTitleTextLength))+'...'
										:summaryObj.summary.length < summaryTitleTextLength 
											? summaryObj.summary 
											: summaryObj.summary.substr(0, summaryObj.summary.lastIndexOf(' ', summaryTitleTextLength))+'...'}</span></SummaryTitle>
									{/*<SummaryTitle><span className='preview-video-title' >{summaryObj.title?summaryObj.title.length < summaryTitleTextLength ? summaryObj.title : summaryObj.title.substring(0, summaryTitleTextLength):summaryObj.summary.length < summaryTitleTextLength ? summaryObj.summary : summaryObj.summary.substring(0, summaryTitleTextLength)}</span></SummaryTitle>*/}
									<SummarySerial>{moment.duration(summaryObj.start*1000).format('hh:mm:ss', {trim: false})}</SummarySerial>
								</SummaryRow>
							)
						})
						:
						null
					}
				</VideoSummaryTableContainer>
			</VideoSummaryContainer>
		</RootContainer>		
	)
}

export default PreviewedVideo;