import {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {
	RootContainer,
	MenuHomeIcon,
	Jumbotron,
	BodyContainer,
	ColorPickerContainer,
	IframeContainer,
	LoadMoreButton,
	PageTitle,
	TitleImage
} from './EmbedSettings.styles';
// import moment from 'moment';
import {Embed} from '../../screens'
import { useParams} from 'react-router-dom';
import copy from 'clipboard-copy';
import {summaryActions} from 'modules/summaries';
// import RingLoader from 'react-spinners/RingLoader';
import { SketchPicker } from 'react-color';
import {toast} from 'react-toastify';

const EmbedSettings = () => {
	const [playerColor, setPlayerColor] = useState('#35599c')
	const {videoId} = useParams();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(summaryActions.customisedEmbedAsyncAction.request({videoId}));
		document.getElementById('root-container').style.height = '80vh';
	}, []);

	// const onPlayerColorSet = (color) => {
	// 	setTimeout(() => setPlayerColor(color), 1000)
	// }

	const onCopy = () => {
		// copy(`<iframe src="${window.location.origin}/embed/video/${videoId}?themeColor=${playerColor.replace('#', '')}" title="ChapterMe video player" height="100%" style="min-width: 100%;background: black; border-radius: 0px;" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" scrolling="no" loading="lazy" allowfullscreen></iframe>`)
		// copy(`<div style="position:relative;padding-top: 56.25%;width:100%;overflow: hidden" > <iframe src="${window.location.origin}/embed/video/${videoId}?themeColor=${playerColor.replace('#', '')}" title="ChapterMe Video Player" style="width:100%;background:black;position:absolute;top: 0;left: 0;bottom: 0;right: 0;height: 100%;border: none; border-radius: 20px;" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" scrolling="no" loading="lazy" allowfullscreen></iframe> </div>`)
		copy(`<iframe width="560" height="315" scrolling="no" src="${window.location.origin}/embed/video/${videoId}?themeColor=${playerColor.replace('#', '')}" title="ChapterMe Video Player" frameborder="0" allow="autoplay; picture-in-picture" allowfullscreen></iframe>`)
		toast.success('iFrame code copied', {
			position: "top-right",
			autoClose: 3000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
		dispatch(summaryActions.embedButtonAsyncAction.request({videoId}));
	}
	return (
		<RootContainer>
			<Jumbotron>
				<MenuHomeIcon onClick={() => {window.open('https://admin.chapterme.co/dashboard', '_self')}} />
				<TitleImage src="https://chapterme.co/assets/img/logo-white.svg" onClick={() => window.open("https://chapterme.co", "_blank") }/>
				<PageTitle>Configure your embed</PageTitle>
			</Jumbotron>
			<BodyContainer>
				<ColorPickerContainer>
					<SketchPicker disableAlpha={true} color={playerColor} onChange={(color, event) => {setPlayerColor(color.hex)}}  />
					<LoadMoreButton onClick={onCopy} >
						{/*{`<iframe src="${window.location.origin}/embed/video/${videoId}?themeColor=${playerColor.replace('#', '')}" title="ChapterMe video player" height="100%" style="min-width: 100%;background: black; border-radius: 0px;" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" scrolling="no" loading="lazy" allowfullscreen></iframe>`}*/}
						{/*{`<div style="position:relative;padding-top: 56.25%;width:100%;overflow: hidden" > <iframe src="${window.location.origin}/embed/video/${videoId}?themeColor=${playerColor.replace('#', '')}" title="ChapterMe Video Player" style="width:100%;background:black;position:absolute;top: 0;left: 0;bottom: 0;right: 0;height: 100%;border: none; border-radius: 20px;" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" scrolling="no" loading="lazy" allowfullscreen></iframe> </div>`}*/}
						{`<iframe width="560" height="315" scrolling="no" src="${window.location.origin}/embed/video/${videoId}?themeColor=${playerColor.replace('#', '')}" title="ChapterMe Video Player" frameborder="0" allow="autoplay; picture-in-picture" allowfullscreen></iframe>`}
					</LoadMoreButton>
				</ColorPickerContainer>
				<IframeContainer>
					<Embed playerColor={playerColor.replace('#', '')} />
				</IframeContainer>
			</BodyContainer>
		</RootContainer>
	)
}

export default EmbedSettings;





					// <iframe src={`${window.location.origin}/embed/video/${videoId}?themeColor=${playerColor.replace('#', '')}`} style={{minWidth: '100%', minHeight: '100%'}} allow="autoplay" frameBorder="0" scrolling="no" loading="lazy"></iframe>




// <ColorPicker content={'colorPicker'} >
// 	<PhotoshopPicker color={playerColor} onChange={(color, event) => {setPlayerColor(color.hex); currentChapterCard.current.style.borderLeftColor = color.hex; summaryHeader.current.style.background = color.hex;}} onAccept={() => {setShowColorPicker(false)}} onCancel={() => {setShowColorPicker(false)}} />
// </ColorPicker>