import styled from 'styled-components';

export const RootContainer = styled.div`
	overflow-y: scroll;
`;

/*
	width: 100vw make page overflowX active
*/ 
export const BannerContainer = styled.div`
	position: relative;
	// width: 100vw;
	height: 40vh;
	background: ${props => props.background?props.background:"linear-gradient(to bottom, #373b44, #4286f4)"};
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const TitleImage = styled.img`
	position: absolute;
	top: 20px;
	left: 20px;
	width: 150px;
	height: auto;
`;

export const ChannelShareButton = styled.div`
	position: absolute;
	top: 20px;
	right: 20px;
	width: 40px;
	height: 40px;
	border-radius: 40px;
	background: ${({theme}) => theme.white};
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: all 0.1s ease;

	&:hover {
		transform: scale(1.05);
	}

	&:active {
		transform: scale(0.9);
	}
`;

export const BannerLogo = styled.img`
	width: 250px;
	height: auto;
`;


// width: 100vw make page overflow
export const ChannelDetailContainer = styled.div`
	// width: 100vw;
	display: flex;
	flex-direction: row;
	align-items: center;

	@media only screen and (max-width: 820px){
		flex-direction: column;
	}
`;

export const ChannelDetailContentContainer = styled.div`
	flex: 1;
	padding: 20px;
`;

export const Divider = styled.div`
	height: 100%;
	width: 2px;
	border-radius: 1px;
	background: ${props => props.background};
`;

export const ChannelDescriptionTitle = styled.div`
	text-align: left;
	font-size: 24px;
	color: ${props => props.color};
	font-weight: bold;
	padding: 10px;
`;

export const ChannelDescription = styled.div`
	text-align: left;
	color: ${({theme}) => theme.darkGrey};
	font-size: 18px;
	padding: 10px;
`;

export const CallToActionButton = styled.div`
	margin: 10px;
	padding: 10px;
	display: inline-block;
	border-radius: 25px;
	background: ${props => props.background?props.background:'#30b7fb'};
	color: ${({theme}) => theme.white};
	cursor: pointer;
	transition: all 0.1s ease;

	&:hover {
		transform: scale(1.05);
	}

	&:active {
		transform: scale(0.9);
	}
`;

export const RecommendationContainer = styled.div`
	height: 60vh;
	background: ${props => props.theme.white};
	padding: 20px 10px;
`;

export const RecommendedSection = styled.div`
	display: flex;
	flex-direction: column;
	background: ${({theme}) => theme.halfWhite};
	padding: 10px;
	border-radius: 20px;
	box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.25);
`;

export const RecommendedSectionTitle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: ${({theme}) => theme.purple};
	font-weight: bold;
	font-size: 18px;
	margin: 10px;
	text-align: left;
`;

export const RecommendedSectionViewMore = styled.div`
	color: ${({theme}) => theme.purple};
	font-weight: bold;
	font-size: 12px;
	text-decoration: underline;
	margin: 10px;
	cursor: pointer;
`;

export const RecommendedSectionScrollable = styled.div`
	display: flex;
	overflow-y: scroll;
`;

export const RecommendationLoadingContainer = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	padding: 30px;
`;

export const RecommendedSlide = styled.div`
	max-width: 240px;
	border-radius: 10px;
	margin: 10px;
	background: #CCC;
	cursor: pointer;
`;

export const RecommendedSlideImage = styled.img`
	width: 240px;
	height: 180px;
`;

export const RecommendedSlideTitle = styled.div`
	margin: 10px;
	color: ${({theme}) => theme.purple};
	font-size: 14px;
	font-weight: bold;
	text-align: left;
`;

export const RecommendedSlideSubtitle = styled.div`
	margin: 5px 10px;
	text-align: left;
	color: ${({theme}) => theme.darkGrey};
	font-size: 12px;
`;

export const ThumbImage = styled.img`
	width: 100%;
	height: auto;
`;