import styled from 'styled-components';
import Image from './404.svg';
import {Link} from 'react-router-dom';

const RootContainer = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const ErrorImage = styled.img.attrs({src: Image})`
	height: 60vh;
	width: auto;
`;

const Heading = styled.h1`
	color: ${({theme}) => theme.purple};
`;

const Subheading = styled.h3`
	color: ${({theme}) => theme.darkGrey};
`;

const ErrorPage = () => {
	document.title = 'Page Not Found | ChapterMe'
	return (
		<RootContainer>
			<ErrorImage />
			<Heading>Ah Ah! Surfing in the wrong area!!!</Heading>
			<Subheading>Let us get you back to the <Link to="/" >Home</Link>.</Subheading>
		</RootContainer>
	)
}

export default ErrorPage;