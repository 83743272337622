import styled from 'styled-components';

export const RootContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	margin: 0;
	padding: 0;
`;

export const ThumbImage = styled.img`
	width: 100%;
	height: auto;
	background-color: #333;
	background-image: url('https://chapterme.co/assets/img/logo-white.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
`;

export const Jumbotron = styled.div`
	background: ${({theme}) => theme.purple};
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	padding: 20px 0;
`;

export const TitleImage = styled.img`
	width: 300px;
	height: auto;

	@media only screen and (max-width: 700px) {
		width: 60%;
		margin: 5px;
	}
`;

export const BottomButtonBar = styled.div`
	display: flex;
	margin: 20px;
	justify-content: center;
`;

export const PageTitle = styled.div`
	font-size: 2em;
	color: ${({theme}) => theme.white};
	font-weight: bold;
`;

export const SearchInputContainer = styled.div`
	position: relative;
	display: flex;
	margin: 20px;
	width: 60%;
	border: none;
	outline: none;
	border-radius: 5px;
	background: ${props => props.theme.white};
	font-weight: bold;
	color: ${({theme}) => theme.darkGrey};
`; 

export const SearchInput = styled.input.attrs({type: 'text', placeholder: "Search by video title, description, chapters, or channel name"})`
	flex: 1;
	border: none;
	outline: none;
	padding: 10px;
	border-radius: 5px;
	background: ${props => props.theme.white};
	font-weight: bold;
	color: ${({theme}) => theme.darkGrey};
`;

export const BodyContainer = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	background-color: ${({theme}) => theme.halfWhite};
`;

export const VideoListContainer = styled.div`
	width: 70vw;
	background-color: ${({theme}) => theme.white};
	padding: 10px 20px;

	@media only screen and (max-width: 950px) {
		width: 100%;
	}
`;

export const LoadMoreButton = styled.div`
	display: inline-block;
	margin: 0px 10px;
	padding: 10px 20px;
	cursor: pointer;
	background: ${({theme}) => theme.halfWhite};
	color: ${({theme}) => theme.lightGrey};
	float: right;
	transition: all 0.3s ease;

	&:hover {
		background: ${({theme}) => theme.purple} !important;
		color: ${({theme}) => theme.white} !important;
	}

	&:active {
		transform: scale(0.9);
		background: ${({theme}) => theme.purple} !important;
		color: ${({theme}) => theme.white} !important;
	}
`;
