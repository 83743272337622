import axios from "axios";

export const recentVideos = (query) => {
  // console.log("QUERY FROM API", query)
  if ((query && query.skip) || query.limit)
    return axios.get(
      `/video/recentVideos/?skip=${query.skip}&limit=${query.limit}`
    );

  return axios.get(`/video/recentVideos/`);
};

export const trendingVideos = () => {
  return axios.get(`/video/trending/`);
};

export const searchVideos = (searchTerm, query) => {
  if (searchTerm.length === 0) return;
  if ((query && query.skip) || query.limit)
    return axios.get(
      `/video/summary/search/${searchTerm}?skip=${query.skip}&limit=${query.limit}`
    );

  return axios.get(`/video/summary/search/${searchTerm}`);
};

export const getChannels = () => {
  // console.log("CHANNELS")
  return axios.get(`/channel/get`);
};
