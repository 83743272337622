import {loginAsyncActions, logoutAction} from './actions';

const initialState = {
	isLoading: false,
	isError: false,
	loginSuccess: false,
	name: '',
	email: '',
}

export default (state = initialState, action) => {
	switch(action.type) {
		case loginAsyncActions.actionNames.request: 
			return {...state, isLoading: true}
		case loginAsyncActions.actionNames.success: {
			return {...state, loginSuccess: true, ...action.payload.result, isLoading: false}
		}
		case loginAsyncActions.actionNames.error: {
			return {...state, loginSuccess: false, isLoading: false}
		}
		case logoutAction.actionName:
			return {...state, loginSuccess: false}
		default:
			return state;
	}
}