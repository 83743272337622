import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  RootContainer,
  SummaryContainer,
  VideoContainer,
  SummaryListContainer,
  NoSummariesContainer,
  NoSummariesText,
  VideoTitleContainer,
  VideoTitleText,
  CopyContentContainer,
  CopyIconContainer,
  CopyTextContainer,
  CopyTextHeading,
  CopyTextContent,
  VideoTitleHover,
} from "../PlayerScreen.style.js";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Summary from "./SummaryCard";
import { VideoPlayer } from "../../components/VideoPlayer.js";
import { LoadingOverlay, Modal } from "components";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { summaryActions } from "modules/summaries";
import { embedActions } from "modules/embed";
import { useParams } from "react-router-dom";
import { BiLink, BiPaste } from "react-icons/bi";
import copy from "clipboard-copy";
import { toast } from "react-toastify";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default ({ playerColor }) => {
  const dispatch = useDispatch();
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [filteredSummaries, setFilteredSummaries] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const summaryHeader = useRef(0);
  const currentChapterCard = useRef(0);
  const summaryObject = useSelector(
    (state) => state.summaryReducer,
    shallowEqual
  );
  const playerState = useSelector((state) => state.playerReducer, shallowEqual);
  const { sessionId } = useSelector(
    (state) => state.embedReducer,
    shallowEqual
  );
  const { videoId } = useParams();
  const query = useQuery();
  const themeColor = query.get("themeColor") || playerColor;
  const showChapters = query.get("showChapters") || "true";

  const onCopyVideo = () => {
    copy(`${summaryObject.videoTitle}
${moment
  .duration(
    summaryObject.summaries[summaryObject.summaries.length - 1].start * 1000
  )
  .format("hh:mm:ss")} | ${summaryObject.summaries.length} chapters 🔖
https://api.chapterme.co/share/video/${videoId}`);
    toast.success("Video link copied", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // const onCopyLink = (index) => {
  // 	const link = `${window.location.origin}/share/video/${videoId}/chapter/${index}`
  // 	copy(link)
  // }

  const onCopyText = (summary) => {
    const text = `${moment
      .duration(summary.start * 1000)
      .format("hh:mm:ss", { trim: false })} - ${summary.title}: ${
      summary.description
    }`;
    copy(text);
  };

  const onVideoEnded = () => {
    dispatch(embedActions.videoEndedAsyncActions.request());
  };

  useEffect(() => {
    document.title = summaryObject.videoTitle || "Untitled Video";
    document.title = "Embed | " + document.title + " | ChapterMe";
    setFilteredSummaries(summaryObject.summaries);
  }, [summaryObject]);

  useEffect(() => {
    dispatch(summaryActions.fetchEmbedSummariesAsyncActions.request(videoId));
    dispatch(
      embedActions.createSessionAsyncActions.request({ videoId, startTime: 0 })
    );
  }, [videoId]);

  useEffect(() => {
    if (sessionId && playerState.currentPlayingTime % 5 === 0)
      dispatch(
        embedActions.endTimeUpdateAsyncActions.request({
          endTime: playerState.currentPlayingTime,
          sessionId,
        })
      );
  }, [playerState]);

  return (
    <RootContainer id="root-container">
      <VideoContainer>
        {useMemo(
          () => (
            <VideoPlayer
              themeColor={themeColor}
              selectedTimestamp={{ start: currentTime }}
              videoId={videoId}
              platform={summaryObject.platform}
              onCopyVideo={onCopyVideo}
              summaryHeader={summaryHeader}
              currentChapterCard={currentChapterCard}
              onVideoEnd={onVideoEnded}
              logo={summaryObject && summaryObject.logo}
              icon={summaryObject && summaryObject.icon}
            />
          ),
          [
            summaryObject.platform,
            summaryObject.summaries,
            currentTime,
            themeColor,
          ]
        )}
      </VideoContainer>
      {playerState.showSummaries && showChapters === "true" ? (
        <SummaryContainer>
          <VideoTitleContainer id="videoTitle-container">
            <VideoTitleContainer>
              <VideoTitleText>
                <p>{summaryObject.videoTitle}</p>
              </VideoTitleText>
              <VideoTitleHover>{summaryObject.videoTitle}</VideoTitleHover>
            </VideoTitleContainer>
          </VideoTitleContainer>
          <SummaryListContainer id="garage-summarylistcontainer">
            {filteredSummaries ? (
              filteredSummaries.length ? (
                filteredSummaries.map((summary, index, summaryArray) => (
                  <Summary
                    key={summary.start}
                    themeColor={themeColor}
                    index={index}
                    summaryArray={summaryArray}
                    videoId={videoId}
                    summary={summary}
                    currentTime={currentTime}
                    setCurrentTime={setCurrentTime}
                    onCopyLink={onCopyText}
                    currentChapterCard={currentChapterCard}
                  />
                ))
              ) : (
                <NoSummariesContainer>
                  <NoSummariesText>
                    This video is not summarised yet
                  </NoSummariesText>
                </NoSummariesContainer>
              )
            ) : (
              <NoSummariesContainer>
                <NoSummariesText>
                  This video is not summarised yet
                </NoSummariesText>
              </NoSummariesContainer>
            )}
          </SummaryListContainer>
        </SummaryContainer>
      ) : null}
      {summaryObject.isLoading ? <LoadingOverlay /> : null}
      {showShareDialog ? (
        <Modal>
          <NoSummariesContainer>
            <CopyContentContainer>
              <CopyIconContainer>
                <BiLink color="#7289da" size={36} />
              </CopyIconContainer>
              <CopyTextContainer>
                <CopyTextHeading>Copy Link</CopyTextHeading>
                <CopyTextContent>
                  This link opens player with that specific time and chapter
                </CopyTextContent>
              </CopyTextContainer>
            </CopyContentContainer>
            <CopyContentContainer>
              <CopyIconContainer>
                <BiPaste color="#7289da" size={36} />
              </CopyIconContainer>
              <CopyTextContainer>
                <CopyTextHeading>Copy Chapter Text</CopyTextHeading>
                <CopyTextContent>
                  Paste this chapter at your video description
                </CopyTextContent>
              </CopyTextContainer>
            </CopyContentContainer>
          </NoSummariesContainer>
        </Modal>
      ) : null}
    </RootContainer>
  );
};
// const onSearch = (text) => {
// 	// setSearchedText(text);
// 	const searchResults = summaryObject.summaries.filter(summary => {
// 		let toInclude = false;

// 		if(summary.type && summary.type === 'INSERT') {
// 			toInclude = true;
// 		}

// 		// if(summary.subpoints) {
// 		// 	const subpointArray = summary.subpoints.map(subpoint => subpoint.title.toLowerCase().includes(text.toLowerCase()))
// 		// 	toInclude = subpointArray.some(res => res === true)
// 		// }

// 		if(summary.title && summary.title.toLowerCase().includes(text.toLowerCase())) {
// 			toInclude = true;
// 		}

// 		if(summary.summary && summary.summary.toLowerCase().includes(text.toLowerCase())) {
// 			toInclude = true;
// 		}

// 		if(summary.description && summary.description.toLowerCase().includes(text.toLowerCase())) {
// 			toInclude = true;
// 		}
// 		return toInclude;
// 	})
// 	setFilteredSummaries(searchResults);
// }
