import styled from "styled-components";
import { GrView } from "react-icons/gr";
import { BsSearch } from "react-icons/bs";

export const RootContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  background: #000;
  height: 100vh;

  @media only screen and (orientation: portrait) {
    flex-direction: column;
  }
`;

export const VideoContainer = styled.div`
  flex: 2;
  background: #000;
  position: relative;

  @media only screen and (orientation: portrait) {
    flex: 1;
    margin-bottom: 5px;
    overflow-y: hidden;
  }
`;

export const SummaryContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.darkGrey};
  text-align: left;
  min-height: 0;
  min-width: 0;
  z-index: 1;

  @media only screen and (orientation: portrait) {
    flex: 3;
  }

  @media only screen and (max-width: 850px) {
    flex: 1.5;
  }

  @media only screen and (max-width: 500px) and (orientation: landscape) {
    position: absolute;
    right: 0;
    z-index: 3;
    width: 35%;
    height: 100vh;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  border-radius: 1rem;
  background: white;
  padding: 10px;

  @media only screen and (max-width: 1200px) {
    display: none;
  }

  @media only screen and (orientation: portrait) and (min-width: 350px) {
    display: flex;
  }
`;
export const SearchIcon = styled(BsSearch)`
  height: 1.2rem;
  width: 1.2rem;
  color: ${({ theme }) => theme.purple};
  cursor: pointer;
  transition: background 300ms;
  border-radius: 10px;

  @media only screen and (max-width: 1400px) {
    // font-size: 0.8rem;
    height: 1rem;
    width: 1rem;
  }
`;
export const SearchInput = styled.input.attrs({
  autoFocus: true,
})`
  flex: 1;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  outline: none;

  @media only screen and (max-width: 1400px) {
    font-size: 0.8rem;
  }
`;

export const SummaryListContainer = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
`;

export const SummaryContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) =>
    props.background ? `#${props.background}` : props.theme.purple};
  padding: 10px;
`;

export const SummaryContainerText = styled.div`
  color: ${({ theme }) => theme.white};
  font-weight: bolder;
  // @media only screen and (max-width: 500px)
  // {
  // 	font-size: 14px;
  // }

  // @media only screen and (max-width: 430px)
  // {
  // 	font-size: 12px;
  // }
`;

export const SummaryCard = styled.div`
  border-left: ${(props) => (props.focused ? "10px" : "5px")} solid
    ${(props) => (props.focused ? `#${props.border}` : props.theme.metallic)};
  padding: 5px;
  padding-left: 10px;
  color: ${({ theme }) => theme.white};
  border-bottom: 1px solid ${(props) => props.theme.lightGrey};
  transition: all 0.2s ease;
  cursor: pointer;
  @media only screen and (max-width: 550px) {
    border-left: ${(props) => (props.focused ? "6px" : "4px")} solid
      ${(props) => (props.focused ? `#${props.border}` : props.theme.metallic)};
  }
`;

export const SummaryTitle = styled.div`
  font-size: 20px;
  margin-bottom: 5px;
  wordbreak: break-all;

  @media only screen and (max-width: 750px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 500px) {
    font-size: 14px;
  }
`;

export const SummaryDescription = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.metallic};
`;

export const SummaryCardTopBar = styled.div`
  display: flex;
  color: ${({ theme }) => theme.metallic};
  align-items: center;
  text-align: left;
`;

export const SummaryCardTopBarLeftPiece = styled.div`
  flex: 1;
  font-size: 14px;
  color: ${({ theme }) => theme.metallic};
`;

export const SummaryCardTopBarRightPiece = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.metallic};
`;

export const ViewIcon = styled(GrView)`
  color: ${({ theme }) => theme.metallic};
`;

export const SummaryCardButtonBar = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

// in ==== chapter.style.js ====
// export const SummaryCardButtonBar = styled.div`
// 	flex: 1;
// 	display: flex;
// 	justify-content: flex-end;
// 	align-items: center;
// 	padding: 10px;
// `;

export const SummaryCardButtonContainer = styled.div`
  cursor: pointer;
`;

export const NoSummariesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
`;

export const NoSummariesText = styled.div`
  text-align: center;
  font-size: 1.1em;
  font-weight: bold;
  color: ${({ theme }) => theme.white};
`;

export const AddChapterButton = styled.div`
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  background: ${({ theme }) => theme.purple};
  color: ${({ theme }) => theme.white};
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.purple};
    background: ${({ theme }) => theme.white};
  }

  &:active {
    transform: scale(0.8);
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin: 10px;
  color: ${({ theme }) => theme.lightGrey};
`;

export const InputLabel = styled.div`
  color: ${({ theme }) => theme.white};
`;

export const SummaryInput = styled.input.attrs({ type: "text" })`
  margin: 5px;
  padding: 5px 10px;
  color: ${({ theme }) => theme.darkGrey};
  border: none;
  outline: none;
  border-radius: 5px;
`;

export const SummaryDescriptionInput = styled.textarea.attrs({
  cols: 27,
  rows: 3,
})`
  margin: 5px;
  padding: 5px 10px;
  color: ${({ theme }) => theme.darkGrey};
  border: none;
  outline: none;
  border-radius: 5px;
`;

export const SummaryDurationInput = styled.input.attrs({
  type: "text",
  size: 1,
  maxLength: 2,
})`
  margin: 5px;
  padding: 5px 10px;
  color: ${({ theme }) => theme.darkGrey};
  border: none;
  outline: none;
  border-radius: 5px;
`;

export const VideoTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background: ${({ theme }) => theme.darkGrey};
  overflow: hidden;
`;

export const VideoTitleText = styled(NoSummariesText)`
  flex: 1;
  position: relative;
  font-size: 1em;
  font-weight: normal;
  text-align: left;
  overflow-x: auto;
  scrollbar-width: none;
  cursor: default;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }

  @media only screen and (max-width: 650px) {
    font-size: 0.8em;
  }

  & p {
    white-space: nowrap;
    scrollbar-width: none;
    padding: 0;
    margin: 0;
  }
  &:hover + div {
    display: block;
  }
  // &:hover p {
  // 	animation: titleGiggle 4s ease-in-out;
  // 	animation-delay: 300ms;
  // }

  @keyframes titleGiggle {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`;
export const VideoTitleHover = styled.div`
  display: none;
  position: absolute;
  top: 0;
  height: max-content;
  font-size: 1em;
  padding: 15px;
  border-radius: 5px;
  color: white;
  background: rgba(0, 0, 0, 0.8);

  @media only screen and (max-width: 650px) {
    font-size: 0.8em;
  }
`;
export const CopyContentContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.metallic};
  cursor: pointer;

  // ==== in Garage.style.js ====
  // @media only screen and (max-width: 570px)
  // {
  // 	display: ${(props) => (props.className === "embed-btn" ? "none" : "flex")};
  // }
`;

export const CopyIconContainer = styled.div`
  border-radius: 50%;
  padding: 10px;
`;

export const CopyTextContainer = styled.div`
  flex: 1;
  padding: 10px;
`;

export const CopyTextHeading = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.white};
`;

export const CopyTextContent = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.metallic};
`;

export const FullVideoButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const FullVideo = styled.div`
  display: inline-block;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  background: ${({ theme }) => theme.purple};
  color: ${({ theme }) => theme.white};
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  align-self: center;
  text-align: center;

  &:hover {
    color: ${({ theme }) => theme.purple};
    background: ${({ theme }) => theme.white};
  }

  &:active {
    transform: scale(0.8);
  }

  @media only screen and (max-width: 650px) {
    font-size: 14px;
  }
`;
