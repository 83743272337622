import { call, put, takeLeading } from "redux-saga/effects";
import {
  fetchSummariesAsyncActions,
  postSummariesAsyncActions,
  shareVideoAsyncAction,
  shareChapterAsyncAction,
  viewChapterAsyncAction,
  customisedEmbedAsyncAction,
  embedButtonAsyncAction,
  fetchEmbedSummariesAsyncActions,
  shareEmbedChapterAsyncAction,
  viewEmbedChapterAsyncAction,
} from "./actions";
import {
  fetchSummaries,
  upsertSummaries,
  shareVideo,
  shareChapter,
  viewChapter,
  customisedEmbed,
  embedButton,
  fetchEmbedSummaries,
  shareEmbedChapter,
  viewEmbedChapter,
} from "./api";
import { toast } from "react-toastify";

function* getSummariesSaga(action) {
  try {
    const response = yield call(fetchSummaries, action.payload);
    yield put(fetchSummariesAsyncActions.success(response.data.result.summary));
  } catch (err) {
    yield put(fetchSummariesAsyncActions.error());
  }
}

function* getEmbedSummariesSaga(action) {
  try {
    const response = yield call(fetchEmbedSummaries, action.payload);
    yield put(
      fetchEmbedSummariesAsyncActions.success({
        ...response.data.result[0],
        thumbnail: response.data.result.thumbnail,
      })
    );
  } catch (err) {
    yield put(fetchEmbedSummariesAsyncActions.error());
  }
}

function* upsertSummariesSaga(action) {
  try {
    const response = yield call(
      upsertSummaries,
      action.payload.videoId,
      action.payload.requestBody
    );
    toast.success("Chapters saved successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put(postSummariesAsyncActions.success());
  } catch (err) {
    toast.error("Something went wrong", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put(postSummariesAsyncActions.error());
  }
}

function* shareVideoSaga(action) {
  try {
    const response = yield call(shareVideo, action.payload.videoId);
    yield put(shareVideoAsyncAction.success());
  } catch (arr) {
    yield put(shareVideoAsyncAction.error());
  }
}

function* shareChapterSaga(action) {
  try {
    const response = yield call(
      shareChapter,
      action.payload.videoId,
      action.payload.startTime
    );
    yield put(shareChapterAsyncAction.success());
  } catch (err) {
    yield put(shareChapterAsyncAction.error());
  }
}

function* viewChapterSaga(action) {
  try {
    const response = yield call(
      viewChapter,
      action.payload.videoId,
      action.payload.startTime
    );
    yield put(viewChapterAsyncAction.success());
  } catch (err) {
    yield put(viewChapterAsyncAction.error());
  }
}

function* viewEmbedChapterSaga(action) {
  try {
    const response = yield call(
      viewEmbedChapter,
      action.payload.videoId,
      action.payload.startTime
    );
    yield put(viewChapterAsyncAction.success());
  } catch (err) {
    yield put(viewChapterAsyncAction.error());
  }
}

function* shareEmbedChapterSaga(action) {
  try {
    const response = yield call(
      shareEmbedChapter,
      action.payload.videoId,
      action.payload.startTime
    );
    yield put(viewChapterAsyncAction.success());
  } catch (err) {
    yield put(viewChapterAsyncAction.error());
  }
}

function* customisedEmbedSaga(action) {
  try {
    const response = yield call(customisedEmbed, action.payload.videoId);
    yield put(customisedEmbedAsyncAction.success());
  } catch (err) {
    yield put(customisedEmbedAsyncAction.error());
  }
}

function* embedButtonSaga(action) {
  try {
    const response = yield call(embedButton, action.payload.videoId);
    yield put(embedButtonAsyncAction.success());
  } catch (err) {
    yield put(embedButtonAsyncAction.error());
  }
}

function* mainSaga() {
  yield takeLeading(
    fetchSummariesAsyncActions.actionNames.request,
    getSummariesSaga
  );
  yield takeLeading(
    fetchEmbedSummariesAsyncActions.actionNames.request,
    getEmbedSummariesSaga
  );
  yield takeLeading(
    postSummariesAsyncActions.actionNames.request,
    upsertSummariesSaga
  );
  yield takeLeading(shareVideoAsyncAction.actionNames.request, shareVideoSaga);
  yield takeLeading(
    shareChapterAsyncAction.actionNames.request,
    shareChapterSaga
  );
  yield takeLeading(
    viewChapterAsyncAction.actionNames.request,
    viewChapterSaga
  );
  yield takeLeading(
    shareEmbedChapterAsyncAction.actionNames.request,
    shareEmbedChapterSaga
  );
  yield takeLeading(
    viewEmbedChapterAsyncAction.actionNames.request,
    viewEmbedChapterSaga
  );
  yield takeLeading(
    customisedEmbedAsyncAction.actionNames.request,
    customisedEmbedSaga
  );
  yield takeLeading(
    embedButtonAsyncAction.actionNames.request,
    embedButtonSaga
  );
}

export default mainSaga;
