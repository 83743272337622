import styled from "styled-components";

import { FaPlay } from "react-icons/fa";
import { BsFillSkipBackwardFill, BsFillSkipForwardFill } from "react-icons/bs";
import { BiFullscreen } from "react-icons/bi";

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SelectNothing = styled.div`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-drag: none;
  cursor: default;
`;

export const OverlayContainer = styled(SelectNothing)`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100vh;
  outline: none;
`;

export const TransparentScreen = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  background: ${({ background, videoState }) =>
    background
      ? videoState !== 0
        ? "linear-gradient(0deg, rgba(0,0,0,1) 10%, rgba(0, 0, 0, 0) 40%)"
        : "rgb(0,0,0)"
      : "none"};
  z-index: 3;
`;
export const LeftSeekScreen = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export const MiddlePausePlayScreen = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const RightSeekScreen = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ShareAndFeedback = styled(Flex, SelectNothing)`
  position: absolute;
  top: 10px;
  right: 5px;
  background: rgba(0, 0, 0, 0.7);
  padding: 3px;
  padding-right: 0;
  border-radius: 3px;
  opacity: ${({ showSettings }) => (showSettings ? 0.8 : 1)};
  transition: all 0.3s ease;
`;

export const ShareContainer = styled(Flex, SelectNothing)`
  flex-direction: column;
  height: 50px;
  width: 50px;
  cursor: pointer;
  z-index: 4;
  margin: 0px 15px;

  @media only screen and (max-width: 700px) {
    // display: none;
    height: 40px;
    width: 40px;
  }
  @media only screen and (max-width: 500px) {
    display: none;
    height: 35px;
    width: 35px;
  }
`;

export const BigPlaybuttonContainer = styled(Flex)`
  position: relative;
  cursor: pointer;
  height: 110px;
  width: 110px;
  background-color: ${(props) => props.background};
  transition: opacity 300ms;
  z-index: 3;
  border-radius: 50%;
  z-index: 2;
  transition: all 300ms;
  transform-origin: bottom;

  &:hover {
    filter: brightness(115%);
    transform: scale(1.02);
  }

  @media only screen and (max-width: 850px) {
    height: 80px;
    width: 80px;
  }

  @media only screen and (max-width: 580px) {
    height: 75px;
    width: 75px;
  }

  @media only screen and (max-width: 400px) {
    height: 60px;
    width: 60px;
  }
`;

export const BigPlaybutton = styled(FaPlay)`
  color: white;
  height: 50px;
  width: 50px;
  margin-left: 10px;

  @media only screen and (max-width: 850px) {
    height: 45px;
    width: 45px;
  }

  @media only screen and (max-width: 580px) {
    height: 40px;
    width: 40px;
    margin-left: 5px;
  }

  @media only screen and (max-width: 400px) {
    height: 30px;
    width: 30px;
    margin-left: 5px;
  }
`;

export const AnimatedPlayPauseBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 20px;
  width: 20px;
  // transition: all 150ms;
  transform: scale(0);
  margin: auto;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  z-index: 1;
`;

export const AnimatedPlayPauseBtn = styled.div.attrs({
  className: "seekTriangle",
})`
  height: 0;
  width: 0;
  border: 0;
  background: transparent;
  transition: all 150ms;
  border-style: solid;
  border-color: transparent;
  ${({ play, seek }) =>
    play
      ? `
  	${seek ? "border-width: 7px 0px 7px 10px;" : "border-width: 4px 0px 4px 6px;"}
	  border-left-color: white;
		margin-left: 2px;
  	 `
      : `
		height: 8px;
		width: 1px;
		border-width: 0 3px;
		border-color: white;
  	 `}
`;
export const SeekingAnimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;

  ${({ inverse }) =>
    inverse
      ? `
		align-items: flex-end;
		border-radius: 0 100% 100% 0 / 50%;
		padding: 0 10px 0 0;
		`
      : `
		align-items: flex-start;
		border-radius: 100% 0 0 100% / 50%;
		padding: 0 0 0 10px;

		`}
`;
export const RightSeekingAnimeContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const LeftSeekingAnimeContainer = styled(RightSeekingAnimeContainer)`
  transform: rotate(180deg);
`;

export const ControlIconsContainers = styled(Flex)`
  position: relative;
  color: white;
  cursor: pointer;
  margin: 0 10px;

  @media (hover: hover) {
    &:hover .toolTipText {
      display: block;
    }
  }

  @media only screen and (max-width: 1000px) {
    margin: 0 5px;
  }
`;

export const ControlsPanel = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  bottom: 0;
  background: ${(props) => props.background + "cf"};
  z-index: 3;
  transition: opacity 300ms;

  // @media only screen and (max-width: 850px) {
  // 	height: 65px;
  // }

  // @media only screen and (max-width: 730px) {
  // 	height: 60px;
  // }
  @media only screen and (max-width: 450px) {
    height: 55px;
  }
  @media only screen and (max-width: 350px) {
    height: 50px;
  }
`;

export const PlayerStateDisplayer = styled(Flex)`
  flex: 1;
  height: 35px;
  color: white;
`;

export const TimeDisplayer = styled(ControlIconsContainers)`
  @media only screen and (max-width: 750px) {
    display: none;
  }
`;

export const Seeker = styled.input.attrs((props) => ({
  type: "range",
  min: 0,
  max: props.max,
  step: "any",
  className: "slider",
}))`
  flex: 20;
  height: 10px;
`;

export const Controls = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
`;

const ControlButtonContainer = styled(Flex)`
  width: 150px;
`;

export const VolumeControls = styled.div`
  // justify-content: flex-start;
  position: relative;

  @media only screen and (max-width: 900px) {
    display: none;
  }
`;
export const VolumeSeekerContainer = styled(Flex)`
  // display: none;
  visibility: ${({ showVolume }) => (showVolume ? "visible" : "hidden")};
  position: absolute;
  bottom: 150%;
  height: 25px;
  width: 160px;
  // padding: 5px 10px;
  border-radius: 5px;
  transform: translateX(-40%);
  background: ${({ themeColor }) => themeColor + "80"};
  // background: grey;
`;
export const VolumeSeeker = styled.input.attrs((props) => ({
  type: "range",
  min: 0,
  max: props.max,
  step: "any",
  className: "volume-slider",
}))`
  height: 7px;
  width: 150px;
  // margin: 5px;
  padding: 0;

  @media only screen and (max-width: 850px) {
    display: none;
  }
`;

export const NextChapter = styled(BsFillSkipForwardFill)`
  @media (hover: hover) {
    &:hover {
      animation: nextchapter 1000ms;
    }
  }

  @keyframes nextchapter {
    0% {
      transform: translateX(0);
    }
    20% {
      transform: translateX(10px);
    }
    100% {
      transform: translateX(0);
    }
  }
`;
export const AnimatedPlayPauseBtnSmall = styled.div.attrs({
  className: "seekTriangle",
})`
  height: 0;
  width: 0;
  border: 0;
  background: transparent;
  transition: width 150ms;
  border-style: solid;
  border-color: transparent;
  ${({ play, seek }) =>
    play
      ? `
  	${
      seek
        ? "border-width: 7px 0px 7px 10px;"
        : "border-width: 10px 0px 10px 14px; margin-left: 5px;"
    }
	  border-left-color: white;
  	 `
      : `
		height: 16px;
		width: 5px;
		border-width: 0 6px;
		border-color: white;
  	 `}
`;
export const PreviousChapter = styled(BsFillSkipBackwardFill)`
  @media (hover: hover) {
    &:hover {
      animation: previousChapter 1000ms 2;
    }
  }

  @keyframes previousChapter {
    0% {
      transform: translateX(0);
    }
    20% {
      transform: translateX(-10px);
    }
    100% {
      transform: translateX(0);
    }
  }
`;
export const PlayPauseContols = styled(ControlButtonContainer)`
  justify-content: space-between;
`;

export const SettingsContainer = styled.div`
  // position: relative;
  justify-content: flex-end;
  // padding: 0px 10px;
  // width: 150px;

  @media only screen and (max-width: 800px) {
    display: none;
  }
`;
export const Fullscreen = styled(BiFullscreen)`
  height: 25px;
  width: 25px;
  color: white;
  transform-origin: bottom;
  transition: transform 300ms;

  @media (hover: hover) {
    &:hover {
      animation: fullscreenAnime 1s;
    }
  }

  @keyframes fullscreenAnime {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }
`;

export const ShowSettings = styled(Flex, SelectNothing)`
  flex-direction: column;
  position: absolute;
  cursor: pointer;
  padding: 10px 0px;
  bottom: 110%;
  right: ${(props) => (props.content === "colorPicker" ? "0px" : "20px")};
  color: white;
  width: 100px;
  background: rgba(0, 0, 0, 0.8);
  font-size: ${(props) => props.font};

  @media only screen and (orientation: portrait) {
    bottom: 0;
    right: 0;
  }
`;

export const SettingsDetails = styled(SelectNothing)`
  padding: 5px 10px;
  margin: 5px;
  text-align: left;
  border-radius: 3px;
  border: ${(props) => (props.selected ? "1px" : "0px")} solid;
  cursor: pointer;

  @media only screen and (max-width: 850px) {
    padding: 0px 10px;
  }
`;

export const LogoImg = styled.img.attrs((props) => ({
  alt: "logo",
  src: props.src,
}))`
  object-fit: cover;
  max-height: 20px;
  ${(props) =>
    props.normal === true
      ? `
			display: block;
			${
        !props.hideFullscreen
          ? `
				@media only screen and (max-width: 1200px)
				{
					& {
						display: none;
					}
				}

				 `
          : ""
      }
			`
      : ` 
			display: none;

			${
        !props.hideFullscreen
          ? `
				@media only screen and (max-width: 1200px)
				{
					& {
						display: block;
					}
				}

				 `
          : ""
      }
			`}
`;

export const ColorPicker = styled.div`
  position: absolute;
  right: 10px;
  bottom: 80px;
`;

export const SummaryTogglebar = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  height: 15%;
  width: 0;
  border-right: 18px solid ${({ borderColor }) => borderColor};
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  cursor: pointer;
  z-index: 3;
  transition: border-right 200ms;

  & > div {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }

  &:active {
    border-right: 17px solid #35599c;
    filter: brightness(75%);
  }

  @media only screen and (orientation: portrait) {
    display: none;
  }

  @media only screen and (max-width: 500px) and (orientation: landscape) {
    right: ${({ showSummaries }) => (showSummaries ? "35%" : 0)};
  }
`;
