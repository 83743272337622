import {asyncActionGenerator, plainActionGenerator} from 'utils';

export const searchAsyncActions = asyncActionGenerator('SEARCH_VIDEOS')

export const loadMoreSearchAsyncActions = asyncActionGenerator('LOAD_MORE_SEARCH_VIDEOS')

export const recommendedAsyncActions = asyncActionGenerator('RECOMMENDED')

export const channelListAsyncActions = asyncActionGenerator('GET_CHANNELS')

export const trendingAsyncActions = asyncActionGenerator('TRENDING');