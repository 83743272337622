import styled from 'styled-components';
import { BsFullscreen, BsFullscreenExit } from 'react-icons/bs';

export const StartingVideoTitle = styled.div`
	position: absolute;
	top: 5rem;
	right: 0;
	left: 50%;
	bottom: 0;
	// display: flex;
	// justify-content: center;
	// align-items: center;
	// margin: auto;
	width: 100%;
	height: fit-content;
	color: white;
	text-align: center;
	font-size: 2rem;
	transform: translateX(-50%);
	z-index: 2;

	@media only screen and (max-width: 1100px)
	{
		top: 3rem;
		font-size: 2rem;
	}
	@media only screen and (max-width: 800px)
	{
		top: 2.5rem;
		font-size: 1.25rem;
	}
	@media only screen and (max-width: 500px)
	{
		top: 2rem;
		font-size: 1.1rem;
	}
	@media only screen and (max-width: 300px)
	{
		top: 10vh;
		font-size: 1rem;
	}
`;
export const UpperStartingScreen = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: calc(50% - 2rem);
	width: 100%;
	background: linear-gradient(to bottom, rgba(0,0,0, .9) 50%, transparent 100%);
	z-index: 1;
`;
export const LowerStartingScreen = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	height: calc(50% - 2rem);
	width: 100%;
	background: linear-gradient(to top, rgba(0,0,0, .9) 0, transparent 100%);
	z-index: 1;
`;
export const OverlayContainer = styled.div`
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	// z-index: 2;
	height: 100%;
	outline: none;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const TransparentScreen = styled.div`
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 0;
	background: ${({isPlaying, videoState, showGradient}) => isPlaying?showGradient?'linear-gradient(0deg, rgba(0,0,0,1) 0, transparent 150px)':'rgba(0, 0, 0, 0)':videoState!==0?'linear-gradient(0deg, rgba(0,0,0,1) 90px, transparent 100%)':'rgb(0,0,0)'};
	transition: background 300ms linear;

	@media (max-width: 770px)
	{
		background: ${({isPlaying, videoState, showGradient}) => isPlaying?showGradient?'linear-gradient(0deg, rgba(0,0,0,1) 0, transparent 150px)':'rgba(0, 0, 0, 0)':videoState!==0?'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0, 0, 0, 0.7) 100%)':'rgb(0,0,0)'};
	}
`;

export const VideoInfoContainer = styled.div`
	width: 1200px;
	// margin: auto 0px;
	// margin-left: 8vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 1;

	@media (max-width: 1300px)
	{
		width: 75%;
	}

	@media (max-width: 1000px)
	{
		width: 90%;
	}
	@media (max-width: 500px)
	{
		width: 100%;
	}
`;

export const InitialVideoInfoContainer = styled.div`
	width: 100%;
	margin: auto 0px;
	// margin-left: 8vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 1;
`;

export const VideoTitle = styled.div`
	font-size: 2em;
	color: white;
	font-weight: bold;
	margin-bottom: 2vh;
	// width: 90%;

	text-align: center;

	@media only screen and (max-width: 1100px)
	{
		width: 80%;
	}
	@media only screen and (max-width: 800px)
	{
		font-size: 1.25em;
	}
	@media only screen and (max-width: 500px)
	{
		font-size: 1.1em;
	}
	@media only screen and (max-width: 300px)
	{
		font-size: 1em;
	}
	${
		({from}) =>
		from === 'pauseState'?
		`
		display: none;
		// @media only screen and (max-width: 490px)
		// {
		// 	display: block;
		// } 
		`
		:null
	}
`;

export const VideoDescription = styled.div`
	width: 90%;
	font-size: 1.125em;
	font-weight: 500;
	color: white;
	text-align: center;

	@media only screen and (max-width: 800px)
	{
		font-size: 1em;
	}
	// @media only screen and (max-width: 490px)
	// {
	// 	display: none;
	// }
`;

export const PlayJoinBtnContainer = styled.div`
	display: flex;
	justify-content: ${({justify}) => 'center'};
	align-items: flex-start;
	height: max-content;
	width: 500px;
	margin: 10px 0;

	@media only screen and (max-width: 650px) {
		width: 400px;
	}
	// @media only screen and (max-width: 500px) {
	// 	flex-direction: column;
	// 	align-items: center;
	// 	justify-content: space-between;
	// 	height: 85px;
	// }
`;

export const InitialPlayJoinBtnContainer = styled(PlayJoinBtnContainer)`
	width: 90%;
	justify-content: center;
`;

export const Button = styled.span`

	font-size: 1em;
	font-weight: 600;
	width: 170px;
	text-align: center;
	color: ${(props) => props.color};
	padding: 10px 15px;
	background: ${(props) => props.themeColor};
	border-radius: 5px;
	margin: 0 10px;
	cursor: pointer;
	transform-origin: bottom;
	transition: border-radius 300ms, transform 300ms, filter 300ms;

	&:active, &:focus {
		border-radius: 3px;
	}

	@media (hover:hover)
	{
		&:hover {
			border-radius: 10px 3px;
			filter: brightness(75%);
			transform: scale(1.05);
		}
	}

	@media only screen and (max-width: 800px)
	{
		font-size: 0.9em;
	}
	@media only screen and (max-width: 600px)
	{
		padding: 10px;
		width: 140px;
	}
	@media only screen and (max-width: 370px)
	{
		width: 130px;
	}
	@media only screen and (max-width: 330px)
	{
		width: 30%;
		padding: 10px 5px;
	}
`;

export const JoinToMemberShipBtn = styled.div`
	position: absolute;
	top: 15px;
	right: 15px;
	color: white;
	padding: ${({padding}) => padding};
	font-size: 0.8em;
	background: rgba(0, 0, 0, .7);
	border-radius: 5px;

	& span {
		font-size: 1em;
		margin: ${({padding}) => '0 '+padding};
	}
	& div {
		display: inline-block;
	}
	@media only screen and (max-width: 800px)
	{
		right: 0px;
		padding: 0;
		background: rgba(0, 0, 0, 0);
		& div {
			display: none;
		}

		// &:hover div {
		// 	display: inline-block;
		// }
	}
	@media (max-width: 400px)
	{
		font-size: 0.7;
		& span {
			font-size: 0.8em;
		}
	}
`;

export const PlayPauseBtnContainer = styled.div`
	margin: auto;
	height: max-content;
	// padding: 10px;
	border-radius: 50%;
	background: ${(props) => props.themeColor};
	height: 4em;
	width: 4em;
	display: flex;
	justify-content: center;
	align-items: center;
    transition: all 300ms;

	@media (max-width: 500px)
	{
		height: 3.7em;
		width: 3.7em;
	}

	@media (hover:hover) {
		&:hover {
			transform: scale(1.04);
			filter: brightness(75%);
		}
	}
`;

export const PauseBtn = styled.div`
	height: 2em;
	width: 10px;
	border-right: 0.8em solid;
	border-left: 0.8em solid;
	border-color: white;
	cursor: pointer;

	@media (max-width: 500px)
	{
		height: 1.8rem;
		width: 8px;
		border-right: 0.7 solid;
		border-left: 0.7 solid;
		border-color: white;
	}
`;

export const PlayBtn = styled.div`
    height: 1.4em;
    width: 1.4em;
    border-style: solid;
    border-width: 1em 0px 1em 1.7em;
    border-color: transparent;
    border-left-color: white;
    cursor: pointer;
    margin-left: 0.4em;
    box-sizing: border-box;
    transition: all 300ms;

	@media (max-width: 500px)
	{
		border-width: 0.9em 0px 0.9em 1.4em;
	}
`;

export const Mute = styled.div`
	height: 30px;
	width: 30px;
	margin: 0 10px;
	padding-right: 10px;
	position: relative;
	overflow: hidden;
	display: inline-block;
	cursor: pointer;
	opacity: ${({muted}) => muted?.5:1};

	span {
		display: block;
		width: 8px;
		height: 8px;
		background: #fff;
		margin: 11px 0 0 2px;

		&:after {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			border-style: solid;
			border-color: transparent #fff transparent transparent;
			border-width: 10px 14px 10px 15px;
			left: -13px;
			top: 5px;
		}

		&:before {
			border-radius: 0 50px 0 0;
			content: '';
			position: absolute;
			width: 5px;
			height: 5px;
			border-style: double;
			border-color: #fff;
			border-width: 7px 7px 0 0;
			left: 18px;
			top: 9px;
			transition: all 0.2s ease-out;
			${({muted}) => 
				muted?
				`
					transform: scale(.5) translate(-15px, 0) rotate(36deg);
					opacity: 0;
				`
				:
				`
					transform: rotate(45deg);
					opacity: 1;

				 `
			}
		}
	}

	&:hover {

		span:before {
			transform: scale(1.2) translate(3px, 0) rotate(42deg);		
	    }
	}
`;

export const Fullscreen = styled(BsFullscreen)`
	height: 20px;
	width: 20px;
	stroke-width: 1;
	color: white;
	cursor: pointer;
	transition: transform 300ms;

	@media(hover:hover)
	{
		&:hover {
			transform: scale(1.15);
		}
	}
`;

export const BottomNav = styled.div`
	position: absolute;
	bottom: 0px;
	left: 0;
	height: 70px;
	width: 100%;
	// margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	@media only screen and (max-width: 600px)
	{
		height: 60px;
	}
	@media only screen and (max-width: 500px)
	{
		height: 50px;
	}
`;

export const Seeker = styled.input.attrs(props => ({
	type: 'range', 
	min: 0, 
	max: props.dur, 
	step: 'any', 
	value: props.currentTime,
}))`

    -webkit-appearance: none;
    width: 99%;
    height: 8px;
    outline: none;
    opacity: 1;
    -webkit-transition: .2s;
    transition: opacity .2s, height 300ms;
    cursor: pointer;
    margin: 0px !important;
    padding: 0px !important;

	&:hover  {
	    opacity: 1;
	    height: 15px;
	  }
	  
	&::-webkit-slider-thumb {
	    -webkit-appearance: none;
	    appearance: none;
	    width: 10px;
	    height: 10px;
	    background: ${(props) => props.themeColor};
	    box-shadow: -1000px 0 0 1000px ${(props) => props.themeColor};
	    cursor: pointer;
	    border: 0;
	    border-radius: 0;
	}
	&::-webkit-slider-thumb:hover {
	    width: 8px;
	    height: 8px;
	    transition: linear 0.2s;
	}

	&::-moz-range-thumb {
	    width: 10px;
	    height: 10px;
	    cursor: pointer;
	    border: 0;
	    border-radius: 0;
	}

	@media screen and (-webkit-min-device-pixel-ratio:0) {
	    & {
	      overflow: hidden;
	      -webkit-appearance: none;
	      background-color: grey;
	    }
	    
	    &::-webkit-slider-runnable-track {
	      height: 10px;
	      -webkit-appearance: none;
	      color: ${(props) => props.themeColor};
	      margin-top: -1px;
	    }
	    
	    &::-webkit-slider-thumb, &::-moz-range-thumb {
	      width: 10px;
	      -webkit-appearance: none;
	      height: 10px;
	      cursor: pointer;
	      background: ${(props) => props.themeColor};
	      box-shadow: -100% 0 0 100% ${(props) => props.themeColor};
	      border: 0;
	      border-radius: 0
	    }

	}

	&::-moz-range-progress {
	  background-color: ${(props) => props.themeColor}; 
	  box-shadow: -1000px 0 0 1000px ${(props) => props.themeColor};
	}
`;

export const BottomLogoContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
`;

export const LogoImage = styled.img`
	width: auto;
	height: 40px;
	margin: 0 10px;
	cursor: pointer;

	@media only screen and (max-width: 650px)
	{
		height: 35px;
		width: auto;
	}
	@media only screen and (max-width: 500px)
	{
		height: 25px;
		width: auto;
	}

	@media (hover:hover) {
		&:hover {
			transform: scale(1.05);
			filter: brightness(75%);
		}
	}
`;

export const Refresh = styled.div`
	display: flex;
	margin-top: 20px;
	font-size: 1em;
	color: white;
	cursor: pointer;

	& span {
		margin-left: 3px;
	}
 `;