import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useHistory, useParams} from 'react-router-dom';
import {LoadingOverlay} from 'components';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {trailerActions} from 'modules/trailer';

const RootContainer = styled.div`
	height: 100vh;
	width: 100vw;
`;

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

export default () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const trailerObject = useSelector(state => state.trailerReducer, shallowEqual)
	// const [trailerIds, setTrailerIds] = useState([])
	const [choosen, setChoosen] = useState('')
	const { trailerId } = useParams();
	// const query = useQuery();
	// const themeColor = query.get('themeColor');
	
	useEffect(() => {
		dispatch(trailerActions.createSessionAsyncActions.request(trailerId))
		dispatch(trailerActions.fetchTrailerAsyncActions.request(trailerId))
	}, [dispatch]);

	useEffect(() => {
		// console.log("TRAILER", trailerObject)
		if(trailerObject.videoIds.length && trailerObject.sessionId) {
			const randomIndex = Math.floor(Math.random() * trailerObject.videoIds.length)
			// console.log("RANDOM_INDEX", randomIndex, trailerObject.videoIds.length)
			setChoosen(trailerObject.videoIds[randomIndex].id)
		}
	}, [trailerObject])

	useEffect(() => {
		if(choosen) {
			// console.log("CHOOSEN", choosen)
			history.replace(`/trailer/${choosen}?trailerId=${trailerId}&sessionId=${trailerObject.sessionId}`)
		}
	}, [choosen])

	const alertUser = e => {
	  e.preventDefault()
	  e.returnValue = ''
	}

	useEffect(() => {
		window.addEventListener('beforeunload', alertUser)
		return () => {
			window.removeEventListener('beforeunload', alertUser)
		}
	}, [])

	return (
		<RootContainer id="root-container">
			<LoadingOverlay/>
		</RootContainer>
	)
}