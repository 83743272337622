import {useState} from 'react';
import moment from 'moment'
import {
	SummaryCard,
	SummaryTitle,
	SummaryDescription,
	SummaryCardTopBar,
	SummaryCardTopBarLeftPiece,
	SummaryCardTopBarRightPiece,
	// SummaryCardButtonBar,
	// SummaryCardButtonContainer,
	CopyContentContainer,
	CopyIconContainer,
	CopyTextContainer,
	CopyTextHeading,
	CopyTextContent,
} from './Chapter.styles'
import { Modal} from 'components';
import {toast} from 'react-toastify';
import { BiLink, BiPaste } from "react-icons/bi";
import copy from 'clipboard-copy';
import Linkify from 'react-linkify';


const Summary = ({summary, currentTime, setCurrentTime, index, videoId, themeColor}) => {
	const [buttonBarOpen, setButtonBarOpen] = useState(true)
	const [showDialog, setShowDialog] = useState(false);

	const onCopyLink = () => {
		const link = `https://api.chapterme.co/share/video/${videoId}/chapter/${index}`
		copy(link)
		toast.success('Link copied successfully', {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}

	const onCopyText = () => {
		const text = `${moment.duration(summary.start*1000).format("hh:mm:ss", {trim: false})} - ${summary.title}: ${summary.description}`
		copy(text);
		toast.success('Full chapter copied successfully', {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}

	return (
		<SummaryCard onClick={() => setCurrentTime(summary.start === currentTime ? currentTime+1: summary.start)} border={themeColor || '7289da'} >
			<SummaryCardTopBar>
				<SummaryCardTopBarLeftPiece>
					{
						moment.duration(summary.start, "seconds").format("hh:mm:ss", { trim: false })
					} 	{summary.subpoints && summary.subpoints.length ? summary.subpoints.length + ' Subpoints' : null} 
				</SummaryCardTopBarLeftPiece>
			</SummaryCardTopBar>
			<SummaryTitle>
			<Linkify componentDecorator={(decoratedHref, decoratedText, key) => ( <a target="_blank" href={decoratedHref} key={key}> {decoratedText} </a> )} >
				{
					summary.title
					?
					summary.title
					:
					summary.summary
				}
			</Linkify>
			</SummaryTitle>
			<SummaryDescription>
				<Linkify componentDecorator={(decoratedHref, decoratedText, key) => ( <a target="_blank" href={decoratedHref} key={key}> {decoratedText} </a> )} >
				{
					summary.description
					?
					summary.description
					:
					null
				}
				</Linkify>
			</SummaryDescription>
			{
				buttonBarOpen
				?
				summary.subpoints && summary.subpoints.length
				?
				summary.subpoints.map(subpoint => (
					<SummaryCard >
						<SummaryCardTopBar>
							<SummaryCardTopBarLeftPiece>
								{
									moment.duration(subpoint.start, "seconds").format("hh:mm:ss", { trim: false })
								}
							</SummaryCardTopBarLeftPiece>
							<SummaryCardTopBarRightPiece />
						</SummaryCardTopBar>
						<SummaryDescription>
							{
								subpoint.title
							}
						</SummaryDescription>
					</SummaryCard>
				))
				:
				null
				:
				null
			}
			{
				showDialog
				?
				<Modal close={() => setShowDialog(false)} >
					<CopyContentContainer onClick={onCopyLink} >
						<CopyIconContainer>
							<BiLink color='#7289da' size={36} />
						</CopyIconContainer>
						<CopyTextContainer>
							<CopyTextHeading>Copy Link</CopyTextHeading>
							<CopyTextContent>This link opens player with that specific time and chapter</CopyTextContent>
						</CopyTextContainer>
					</CopyContentContainer>
					<CopyContentContainer onClick={onCopyText} >
						<CopyIconContainer>
							<BiPaste color='#7289da' size={36} />
						</CopyIconContainer>
						<CopyTextContainer>
							<CopyTextHeading>Copy Chapter Text</CopyTextHeading>
							<CopyTextContent>Paste this chapter at your video description</CopyTextContent>
						</CopyTextContainer>
					</CopyContentContainer>
				</Modal>
				:
				null
			}
		</SummaryCard>
	)
}

export default Summary;