import React, { useEffect, useState, useMemo } from "react";
import { IoIosPlay } from "react-icons/io";
import {
  Flex,
  IframePlayer,
  IframeContainer,
  PlaceHolderThumbnail,
  PlayButtonContainer,
} from "./players.styles.js";
import Overlay from "./overlay/overlay.js";
import { LoadingOverlay } from "components";
import { Overlay as TrailerOverlay } from "./Trailer_overlay/Overlay";
import { IconContext } from "react-icons";
import { shallowEqual, useSelector } from "react-redux";

let player,
  pausedVideo = true,
  timeUpdatingInterval;
var existingScript;

const YoutubePlayer = ({
  selectedTimestamp,
  videoId,
  addChapter,
  setCurrentDuration,
  onCopyVideo,
  summaryHeader,
  currentChapterCard,
  hideChapterOnPlay,
  themeColor,
  hideChapterSeekerBtn,
  hideFullscreen,
  trailer,
  trailerObject,
  onPlay,
  onActionButtonClick,
  onVideoStart,
  onVideoEnd,
  videoPaused,
  logo,
  icon,
}) => {
  selectedTimestamp = selectedTimestamp ? selectedTimestamp : {};

  const summaryObject = useSelector(
    (state) => state.summaryReducer,
    shallowEqual
  );

  const thumbnail =
    summaryObject?.thumbnail ||
    `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`;

  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(100);
  const [playingSpeed, setPlayingSpeed] = useState(1);

  const [videoState, setVideoState] = useState(-1);
  // videoStates
  // -1 (unstarted)
  // 0 (ended)
  // 1 (playing)
  // 2 (paused)
  // 3 (buffering)
  // 5 (video cued)

  useEffect(() => {
    existingScript = document.getElementById("youtube-script");
    if (!existingScript && !window.YT) {
      var script = document.createElement("script");
      script.id = "youtube-script";
      script.src = "https://www.youtube.com/iframe_api";

      var firstScriptTag = document.getElementsByTagName("head")[0];
      firstScriptTag.appendChild(script);
    }

    window.onYouTubeIframeAPIReady = () => {
      // the Player object is created uniquely based on the id in props

      player = new window.YT.Player(`youtube-player-${videoId}`, {
        videoId: videoId,
        playerVars: {
          origin: window.location.origin,
          controls: 0,          
          rel: 0,
          widget_referrer: 'ChapterMe'
        },
        events: {
          onReady: (video) => {
            setIsPlayerReady(true);
            // player.mute();
            let dur = player.getDuration();
            setDuration(dur);
          },
          onStateChange: (state) => {
            // ended (0), paused (2), video cued (5) or unstarted (-1)
            // console.log('video state', state.data, currentTime);
            setVideoState(state.data);
            if (state.data === 0) {
              //video ended
              clearInterval(timeUpdatingInterval);
              setCurrentTime(0);
              setIsPlaying(false);
              if (onVideoEnd) onVideoEnd();
              // player.stopVideo();
            }
            if (state.data === 1) {
              //video playing
              clearInterval(timeUpdatingInterval);
              timeUpdatingInterval = setInterval(() => {
                let currentTime = player.getCurrentTime();
                setCurrentTime(currentTime);
                if (currentTime >= 4 && currentTime <= 5) {
                  window?.parent?.postMessage(
                    JSON.stringify({ videoId, played: true }),
                    "*"
                  );
                }
                setCurrentDuration(currentTime);
              }, 1000);
              if (onVideoStart) onVideoStart();
              setIsPlaying(true);
            } else if (state.data === 2) {
              //video paused
              if (videoPaused) videoPaused();
              clearInterval(timeUpdatingInterval);
              setIsPlaying(false);
            } else if (state.data === 3)
              // buffering
              setIsPlaying(true);
          },
          onPlaybackRateChange: (rate) => {
            setPlayingSpeed(rate.data);
          },
          onError: (event) => {
            console.log("Error in YouTube player", event);
          },
          onApiChange: () => {
            // console.log("ON API CHANGE")
          },
        },
      });
    };
  }, [videoId, setDuration, setCurrentTime, onVideoEnd]);

  const play = (state) => {
    if (!player || !duration) return;
    setIsPlaying(state);
    if (state) {
      player.playVideo();
    } else {
      // this start video at selectedTimestamp.startTime value if it exists
      if (
        currentTime === 0 &&
        selectedTimestamp &&
        selectedTimestamp.startTime
      ) {
        player.cueVideoById(videoId, selectedTimestamp.startTime);
        setCurrentTime(selectedTimestamp.startTime);
      } else if (currentTime === 0 && !hideChapterSeekerBtn)
        //shows thumnail on start
        player.cueVideoById(videoId, currentTime);
      player.pauseVideo();
    }
  };

  const seekTo = (seconds) => {
    if (!player || !duration) return;
    player.seekTo(seconds);
    setCurrentTime(seconds);
    if (!currentTime) play(false);
  };

  const setVolume = (value) => {
    if (!player || !duration) return;
    setVolumeLevel(value);
    player.setVolume(value);
    if (parseInt(value) === 0) setIsMuted(true);
    else setIsMuted(false);
  };

  const changePlaybackSpeed = (rate) => {
    if (!player || !duration) return;
    player.setPlaybackRate(rate);
  };

  useEffect(() => {
    if (
      trailerObject === undefined &&
      selectedTimestamp.start !== undefined &&
      duration
    ) {
      // console.log(selectedTimestamp);
      if (selectedTimestamp.start) {
        // this start video at selectedTimestamp.start value if it exists
        seekTo(selectedTimestamp.start);
        setCurrentTime(selectedTimestamp.start);
      }
      if (hideChapterSeekerBtn) {
        // this shows thumbnail mutedon start of the shared chapter video
        player.cueVideoById(videoId, selectedTimestamp.start);
        play(false);
      }
    }
  }, [selectedTimestamp.start, duration]);

  useEffect(() => {
    if (
      trailerObject === undefined &&
      selectedTimestamp.startTime &&
      duration
    ) {
      // this start video at selectedTimestamp.startTime value if it exists
      seekTo(selectedTimestamp.startTime);
      setCurrentTime(selectedTimestamp.startTime);
    }
  }, [selectedTimestamp.startTime, duration]);

  return (
    <div>
      {!trailer && videoState === -1 ? (
        <Flex>
          {/*https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg*/}
          <PlaceHolderThumbnail src={thumbnail} />
          <IconContext.Provider
            value={{
              color: "#fff",
              className: "play-button",
              size: "5rem",
              background: "white",
            }}
          >
            <PlayButtonContainer background={themeColor}>
              <IoIosPlay />
            </PlayButtonContainer>
          </IconContext.Provider>
        </Flex>
      ) : null}
      {
        <IframeContainer opacityFactor={!trailer && videoState == -1}>
          <IframePlayer
            tabIndex={-1}
            id={`youtube-player-${videoId}`}
            trailer={trailer}
            onClick={() => document.getElementById("overlayContainer").focus()}
          />
        </IframeContainer>
      }
      {isPlayerReady ? (
        trailer ? (
          <TrailerOverlay
            onPlay={onPlay}
            onActionButtonClick={onActionButtonClick}
            trailerObject={trailerObject}
            themeColor={themeColor}
            currentTime={currentTime}
            duration={duration}
            isPlaying={isPlaying}
            play={play}
            seekTo={seekTo}
            volume={{ volume: volumeLevel, setVolume: setVolume }}
            videoState={videoState}
          />
        ) : (
          <Overlay
            onCopyVideo={onCopyVideo}
            addChapter={addChapter}
            currentTime={currentTime}
            play={play}
            hideChapterOnPlay={hideChapterOnPlay}
            isPlaying={isPlaying}
            duration={duration}
            isMuted={isMuted}
            volumeLevel={volumeLevel}
            seekTo={seekTo}
            setVolume={setVolume}
            changePlaybackSpeed={changePlaybackSpeed}
            volume="100"
            playingSpeed={playingSpeed}
            summaryHeader={summaryHeader}
            currentChapterCard={currentChapterCard}
            themeColor={themeColor}
            hideChapterSeekerBtn={hideChapterSeekerBtn}
            hideFullscreen={hideFullscreen}
            videoState={videoState}
            logo={logo}
            icon={icon}
          />
        )
      ) : (
        <LoadingOverlay />
      )}
    </div>
  );
};

export default YoutubePlayer;
