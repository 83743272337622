import {useState, useEffect} from 'react';
import moment from 'moment';
import {useSelector, shallowEqual} from 'react-redux';
import {
	SummaryCard,
	SummaryTitle,
	SummaryDescription,
	SummaryCardTopBar,
	SummaryCardTopBarLeftPiece,
	SummaryCardTopBarRightPiece,
	SummaryCardButtonBar,
	SummaryCardButtonContainer,
	// SummaryContainerHeader,
	// SummaryContainerText,
	CopyContentContainer,
	CopyIconContainer,
	CopyTextContainer,
	CopyTextHeading,
	CopyTextContent,
} from './Garage.styles'
import { Modal} from 'components';
import {toast} from 'react-toastify';
import { BiLink, BiPaste } from "react-icons/bi";
import copy from 'clipboard-copy';
import { MdShare } from "react-icons/md";
import Linkify from 'react-linkify';
import { useDispatch } from 'react-redux';
import {summaryActions} from 'modules/summaries';

const Summary = ({summary, currentTime, setCurrentTime, index, videoId, summaryArray, currentChapterCard, themeColor}) => {
	const [buttonBarOpen, setButtonBarOpen] = useState(true);
	const [showDialog, setShowDialog] = useState(false);
	const [selected, setSelected] = useState(false);
	const playerState = useSelector(state => state.playerReducer, shallowEqual);
	const dispatch = useDispatch();

	useEffect(() => {
		if(summaryArray.length === 1) {
			setSelected(true);
		}
		else if(index === summaryArray.length-1 && summaryArray[index].start <= playerState.currentPlayingTime) {
			setSelected(true);
		}
		else if(summaryArray[index].start <= playerState.currentPlayingTime && summaryArray[index+1].start > playerState.currentPlayingTime) {
			setSelected(true);
		}
		else {
			setSelected(false)
		}
	}, [summary.start, playerState]);

	useEffect(() => {
		if (selected)
		{
			let y = document.getElementById('current-chapter').offsetTop - (document.getElementById('videoTitle-container').clientHeight+document.getElementById('summary-header').clientHeight+document.getElementById('summary-header').offsetTop);
			// console.log(y, document.getElementById('videoTitle-container').clientHeight, document.getElementById('summary-header').clientHeight);
			document.getElementById('garage-summarylistcontainer').scrollTo({
			  top: y,
			  behavior: 'smooth'
			});
			dispatch(summaryActions.viewChapterAsyncAction.request({videoId, startTime: summary.start}));
		}
	}, [selected])

	const onCopyLink = () => {
		const link = `https://api.chapterme.co/share/video/${videoId}/chapter/${summary.start}`
		copy(link)
		toast.success('Chapter link copied', {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
		dispatch(summaryActions.shareChapterAsyncAction.request({videoId, startTime: summary.start}));
	}

	const onCopyText = () => {
		const text = `${moment.duration(summary.start*1000).format("hh:mm:ss", {trim: false})} - ${summary.title}: ${summary.description}`
		copy(text);
		toast.success('Full chapter copied successfully', {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}

	return (
		<SummaryCard id={selected?'current-chapter':null} focused={selected} border={themeColor || '7289da'} ref={selected?currentChapterCard:null} onClick={() => setCurrentTime(summary.start===currentTime?currentTime+1:summary.start)} >
			<SummaryCardTopBar>
				<SummaryCardTopBarLeftPiece>
					{
						moment.duration(summary.start, "seconds").format("hh:mm:ss", { trim: false })
					} 	{summary.subpoints && summary.subpoints.length ? summary.subpoints.length + ' Subpoints' : null} 
				</SummaryCardTopBarLeftPiece>
				<SummaryCardTopBarRightPiece>
					{
						buttonBarOpen
						?
						<SummaryCardButtonBar>
							<SummaryCardButtonContainer onClick={() => onCopyLink()} >
								<MdShare size='1.5em' style={{margin: '5px'}} color='#99aab5' />
							</SummaryCardButtonContainer>
						</SummaryCardButtonBar>
						:
						null
					}
				</SummaryCardTopBarRightPiece>
			</SummaryCardTopBar>
			<SummaryTitle>
				<Linkify componentDecorator={(decoratedHref, decoratedText, key) => ( <a target="_blank" href={decoratedHref} key={key}> {decoratedText} </a> )} >
					{
						summary.title
						?
						summary.title
						:
						summary.summary
					}
				</Linkify>
			</SummaryTitle>
			<SummaryDescription>
				<Linkify componentDecorator={(decoratedHref, decoratedText, key) => ( <a target="_blank" href={decoratedHref} key={key}> {decoratedText} </a> )} >
					{
						summary.description
						?
						summary.description
						:
						null
					}
				</Linkify>
			</SummaryDescription>
			{
				buttonBarOpen
				?
				summary.subpoints && summary.subpoints.length
				?
				summary.subpoints.map(subpoint => (
					<SummaryCard >
						<SummaryCardTopBar>
							<SummaryCardTopBarLeftPiece>
								{
									moment.duration(subpoint.start, "seconds").format("hh:mm:ss", { trim: false })
								}
							</SummaryCardTopBarLeftPiece>
							<SummaryCardTopBarRightPiece />
						</SummaryCardTopBar>
						<SummaryDescription>
							<Linkify componentDecorator={(decoratedHref, decoratedText, key) => ( <a target="_blank" href={decoratedHref} key={key}> {decoratedText} </a> )} >
								{
									subpoint.title
								}
							</Linkify>
						</SummaryDescription>
					</SummaryCard>
				))
				:
				null
				:
				null
			}
			{
				showDialog
				?
				<Modal close={() => setShowDialog(false)} >
					<CopyContentContainer onClick={onCopyLink} >
						<CopyIconContainer>
							<BiLink color='#7289da' size={36} />
						</CopyIconContainer>
						<CopyTextContainer>
							<CopyTextHeading>Copy Link</CopyTextHeading>
							<CopyTextContent>This link opens player with that specific time and chapter</CopyTextContent>
						</CopyTextContainer>
					</CopyContentContainer>
					<CopyContentContainer onClick={onCopyText} >
						<CopyIconContainer>
							<BiPaste color='#7289da' size={36} />
						</CopyIconContainer>
						<CopyTextContainer>
							<CopyTextHeading>Copy Chapter Text</CopyTextHeading>
							<CopyTextContent>Paste this chapter at your video description</CopyTextContent>
						</CopyTextContainer>
					</CopyContentContainer>
				</Modal>
				:
				null
			}
		</SummaryCard>
	)
}

export default Summary;