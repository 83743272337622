import {asyncActionGenerator, plainActionGenerator} from 'utils';

export const fetchSummariesAsyncActions = asyncActionGenerator('FETCH_SUMMARIES')

export const fetchEmbedSummariesAsyncActions = asyncActionGenerator('FETCH_EMBED_SUMMARIES')

export const postSummariesAsyncActions = asyncActionGenerator('POST_SUMMARIES')

export const mutateSummariesAction = plainActionGenerator('MUTATE_SUMMARIES');

export const shareVideoAsyncAction = asyncActionGenerator('SHARE_VIDEO');

export const shareChapterAsyncAction = asyncActionGenerator('SHARE_CHAPTER');

export const viewChapterAsyncAction = asyncActionGenerator('VIEW_CHAPTER');

export const shareEmbedChapterAsyncAction = asyncActionGenerator('SHARE_EMBED_CHAPTER');

export const viewEmbedChapterAsyncAction = asyncActionGenerator('VIEW_EMBED_CHAPTER');

export const customisedEmbedAsyncAction = asyncActionGenerator('CUSTOMISED_EMBED');

export const embedButtonAsyncAction = asyncActionGenerator('EMBED_ACTION');