import {searchAsyncActions, loadMoreSearchAsyncActions, recommendedAsyncActions, channelListAsyncActions, trendingAsyncActions} from './actions';

const initialState = {
	isRecommendedLoading: false,
	isSearchLoading: false,
	isChannelListLoading: false,
	isTrendingLoading: false,
	isError: false,
	recommendedVideos: [],
	searchedVideos: [],
	trendingVideos: [],
	channelList: [],
}

export default (state = initialState, action) => {
	switch(action.type) {
		case recommendedAsyncActions.actionNames.request: 
			return {...state, isRecommendedLoading: true}

		case recommendedAsyncActions.actionNames.success: 
			return {...state, recommendedVideos: action.payload, isRecommendedLoading: false}

		case recommendedAsyncActions.actionNames.error: 
			return {...state, isRecommendedLoading: false}

		case searchAsyncActions.actionNames.request: 
			return {...state, isSearchLoading: true}

		case searchAsyncActions.actionNames.success: 
			return {...state, searchedVideos: action.payload, isSearchLoading: false}

		case searchAsyncActions.actionNames.error: 
			return {...state, isSearchLoading: false}

		case loadMoreSearchAsyncActions.actionNames.request: 
			return {...state, isSearchLoading: true}

		case loadMoreSearchAsyncActions.actionNames.success: 
			return {...state, searchedVideos: action.payload, isSearchLoading: false}

		case loadMoreSearchAsyncActions.actionNames.error: 
			return {...state, isSearchLoading: false}

		case channelListAsyncActions.actionNames.request: 
			return {...state, isChannelListLoading: true}

		case channelListAsyncActions.actionNames.success: 
			return {...state, channelList: action.payload, isChannelListLoading: false}

		case channelListAsyncActions.actionNames.error: 
			return {...state, isChannelListLoading: false}

		case trendingAsyncActions.actionNames.request: 
			return {...state, isTrendingLoading: true}

		case trendingAsyncActions.actionNames.success: 
			return {...state, trendingVideos: action.payload, isTrendingLoading: false}

		case trendingAsyncActions.actionNames.error: 
			return {...state, isTrendingLoading: false}

		default:
			return state;
	}
}