import {useState, useEffect} from 'react';
import {
	RootContainer,
	Jumbotron,
	SearchInputContainer,
	SearchInput,
	BodyContainer,
	VideoListContainer,
	LoadMoreButton,
	PageTitle,
	BottomButtonBar,
	TitleImage
} from './VideoList.styles';
import Skeleton from 'react-loading-skeleton';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
// import moment from 'moment';
import {PreviewedVideo, Thumbnail} from 'components';
import {homeActions} from 'modules/home';
// import axios from 'axios';
import {useHistory, useLocation} from 'react-router-dom';
// import RingLoader from 'react-spinners/RingLoader';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const VideoList = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const query = useQuery();
	const homeState = useSelector(state => state.homeReducer, shallowEqual)
	const [searchPage, setSearchPage] = useState(0);
	const [searchText, setSearchText] = useState();

	const onSearch = (value) => {
		setSearchText(value);
		setTimeout(() => dispatch(homeActions.searchAsyncActions.request({searchTerm: value, query: {skip: 0, limit: 20}})), 2000)
	}

	const onVideoClick = (videoId, startTime) => {
		window.open(`${window.location.origin}/player/video/${videoId}${startTime?'?startTime='+startTime:''}`)
	}
 
	useEffect(() => {
		if(location.pathname === '/recentVideos') {
			// console.log("calling recent videos")
			dispatch(homeActions.recommendedAsyncActions.request({skip: (searchPage*20), limit: 20}))
		}	

		const initialSearchQuery = query.get('searchText')
		// console.log("LOCATION", location, initialSearchQuery)
		if(initialSearchQuery) {
			setSearchText(initialSearchQuery)
			dispatch(homeActions.searchAsyncActions.request({searchTerm: initialSearchQuery, query: {skip: 0, limit: 20}}))
		}
	}, [dispatch])

	useEffect(() => {
		// console.log("SEARCH PAGE CHANGED", searchPage)
		if(searchPage && location.pathname === '/recentVideos') {
			dispatch(homeActions.recommendedAsyncActions.request({skip: (searchPage*20), limit: 20}))			
		}
	}, [searchPage])

	if(homeState.isRecommendedLoading || homeState.isSearchLoading) {
		return (
			<RootContainer id="root-container">
				<Jumbotron>
					{
						location.pathname === '/recentVideos'
						?
						<PageTitle>Recent Videos</PageTitle>
						:
						<SearchInputContainer>
							<SearchInput autoFocus onChange={evt => onSearch(evt.target.value)} value={searchText} />
						</SearchInputContainer>
					}
				</Jumbotron>
				<BodyContainer>
					<VideoListContainer>
						<Skeleton height={350} />
					</VideoListContainer>
				</BodyContainer>
			</RootContainer>
		)
	}

	return (
		<RootContainer id="root-container">
			<Jumbotron>
				<TitleImage src="https://chapterme.co/assets/img/logo-white.svg" onClick={() => {/*window.open("https://chapterme.co", "_blank")*/; history.push('/')} }/>
				{
					location.pathname === '/recentVideos'
					?
					<PageTitle>Recent Videos</PageTitle>
					:
					<SearchInputContainer>
						<SearchInput autoFocus onChange={evt => onSearch(evt.target.value)} value={searchText} />
					</SearchInputContainer>
				}
			</Jumbotron>
			<BodyContainer>
				<VideoListContainer>
					{
						location.pathname === '/recentVideos'
						?
						homeState.recommendedVideos.map((summary, index) => <PreviewedVideo key={summary.videoId+summary.videoTitle+index} onVideoClick={onVideoClick} summary={summary} Thumbnail={Thumbnail} fromPreview />)
						:
						homeState.searchedVideos.map((summary, index) => <PreviewedVideo key={summary.videoId+summary.videoTitle+index} onVideoClick={onVideoClick} summary={summary} Thumbnail={Thumbnail} fromPreview />)
					}
					{
						location.pathname === '/recentVideos'
						?
						<BottomButtonBar>
							<LoadMoreButton onClick={() => !homeState.isRecommendedLoading?setSearchPage(prevState => prevState-1):null} disabled={homeState.isRecommendedLoading} >
								Prev Page
							</LoadMoreButton>
							<LoadMoreButton onClick={() => !homeState.isRecommendedLoading?setSearchPage(prevState => prevState+1):null} disabled={homeState.isRecommendedLoading} >
								Next Page
							</LoadMoreButton>
						</BottomButtonBar>
						:
						null
					}
				</VideoListContainer>
			</BodyContainer>
		</RootContainer>
	)
}

export default VideoList;