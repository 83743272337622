import {feturedVideosAsyncActions} from './actions';

const initialState = {
	isFeaturedLoading: false,
	isError: false,
	featuredVideos: [],
	channelData: {},
}

export default (state = initialState, action) => {
	switch(action.type) {
		case feturedVideosAsyncActions.actionNames.request: 
			return {...state, featuredVideos: [], isFeaturedLoading: true}

		case feturedVideosAsyncActions.actionNames.success: 
			return {...state, featuredVideos: action.payload.channelVideos, channelData: action.payload.channel, isFeaturedLoading: false}

		case feturedVideosAsyncActions.actionNames.error: 
			return {...state, isFeaturedLoading: false}

		default:
			return state;
	}
}