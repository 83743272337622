import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
// import { PhotoshopPicker } from 'react-color';

import Chapterme_short_logo from "../../chapterme_short_icon.png";

import {
  toggleFullscreen,
  Settings,
  playPauseAnimation,
  leftSeekingAnimation,
  rightSeekingAnimation,
  format,
  SettingIcons,
} from "./Settings_and_others.js";

import {
  OverlayContainer,
  TransparentScreen,
  LeftSeekScreen,
  MiddlePausePlayScreen,
  RightSeekScreen,
  ShareContainer,
  BigPlaybuttonContainer,
  ControlsPanel,
  PlayerStateDisplayer,
  TimeDisplayer,
  Seeker,
  Controls,
  ControlIconsContainers,
  VolumeControls,
  VolumeSeekerContainer,
  VolumeSeeker,
  PlayPauseContols,
  AnimatedPlayPauseBtnSmall,
  SettingsContainer,
  ShowSettings,
  SettingsDetails,
  BigPlaybutton,
  ShareAndFeedback,
  ColorPicker,
  SummaryTogglebar,
  LogoImg,
  AnimatedPlayPauseBtnContainer,
  AnimatedPlayPauseBtn,
  SeekingAnimeContainer,
  RightSeekingAnimeContainer,
  LeftSeekingAnimeContainer,
  NextChapter,
  PreviousChapter,
  Fullscreen,
} from "./overlay.style.js";
import { playerActions } from "modules/player";
import { IconContext } from "react-icons";
import {
  BsFillSkipBackwardFill,
  BsFillSkipForwardFill,
  BsFillVolumeDownFill,
  BsFillVolumeUpFill,
} from "react-icons/bs";
import { MdVolumeOff, MdRefresh } from "react-icons/md";
import { BiFullscreen } from "react-icons/bi";
import { AiOutlineShareAlt, AiOutlineDoubleLeft } from "react-icons/ai";
import { VscFeedback } from "react-icons/vsc";

import "./overlay.css";
import useQuery from "utils/useQuery";

let isSeeking = false,
  seekingTime,
  volumeRemember,
  mouseMovingTimeout,
  seekerMouseMoveWorking,
  showSummaries = true;
let lastSeekTime = 0,
  seekedTime = 10,
  showVolumeTimeout,
  backgroundGradientTimeout;

const Overlay = ({
  platform,
  onCopyVideo,
  currentTime,
  play,
  isPlaying,
  duration,
  isMuted,
  volumeLevel,
  seekTo,
  setVolume,
  changePlaybackSpeed,
  volume,
  playingSpeed,
  addChapter,
  setShowSummary,
  summaryHeader,
  currentChapterCard,
  themeColor,
  hideChapterSeekerBtn,
  hideFullscreen,
  hideChapterOnPlay,
  videoState,
  logo,
  icon,
}) => {
  themeColor = `#${themeColor || "35599c"}`;

  let dur = format(duration);

  const query = useQuery();
  const chaptersToggle = query.get("chaptersToggle");
  const showChapters = query.get("showChapters") || "true";

  let cur_time = format(currentTime);
  const dispatch = useDispatch();
  const [showSettings, setShowSetting] = useState(false);
  const [timeShowerSize, setTimeShowerSize] = useState("20px");
  const [showControls, setShowControls] = useState(true);
  const [hoveringTime, setHoveringTime] = useState(0);
  // const [isFullScreen, setIsFullScreen] = useState(false);
  const [showVolume, setShowVolume] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showBackgroundGradient, setShowBackgroundGradient] = useState(true);
  // const [themeColor, setPlayedrColor] = useState(`#${themeColor || '35599c'}`);

  const summaryObject = useSelector(
    (state) => state.summaryReducer,
    shallowEqual
  );
  const playerState = useSelector((state) => state.playerReducer, shallowEqual);

  const overlayContainer = useRef(null);
  const volumeController = useRef(volume);
  const seeker = useRef(0);
  const bigPlayButtonContainer = useRef(null);
  const bigPlayButton = useRef(null);
  const controlsContainer = useRef(null);
  const shareContainer = useRef(null);
  const hoveringTimeShower = useRef(null);
  const animatedPlayPause = useRef(null);

  const controlsHandler = useCallback(() => {
    setShowControls(true);
    clearTimeout(mouseMovingTimeout);
    if (isPlaying && videoState !== 0)
      mouseMovingTimeout = setTimeout(() => {
        if (currentTime !== 0) setShowControls(false);
      }, 2000);
  }, [isPlaying, videoState]);

  const onPlay = (state) => {
    playPauseAnimation();
    if (
      hideChapterOnPlay &&
      state &&
      playerState.showSummaries &&
      window.innerWidth > window.innerHeight
    )
      //hiding onPlay action if hidechapter is true
      dispatch(playerActions.toggleSummariesAction.action());
    else if (
      window.innerWidth < 501 &&
      state &&
      playerState.showSummaries &&
      window.innerWidth > window.innerHeight
    )
      //hiding onPlay action small screens
      dispatch(playerActions.toggleSummariesAction.action());

    play(state);
  };

  const onSeek = (value) => {
    if (value < currentTime) leftSeekingAnimation();
    else if (value > currentTime) rightSeekingAnimation();

    seekTo(value);
  };

  const removeGradient = () => {
    clearTimeout(backgroundGradientTimeout);

    backgroundGradientTimeout = setTimeout(
      () => {
        setShowBackgroundGradient(false);
      },
      currentTime === 0 ? 3000 : 500
    );
  };

  const handleKeydownEvent = useCallback(
    (e) => {
      if (e.key === " ") {
        e.preventDefault();
        if (isPlaying) setShowControls(true);
        else setShowControls(false);
        // bigPlayButton.current.click();
        onPlay(!isPlaying);
      }

      if (e.key === "ArrowUp") {
        e.preventDefault();
        // setShowControls(true)
        let vol = volumeLevel + volume / 10;
        if (vol > volume) vol = volume;
        setVolume(vol);
      }

      if (e.key === "ArrowDown") {
        e.preventDefault();
        // setShowControls(true)
        let vol = volumeLevel - volume / 10;
        if (vol < 0) vol = 0;
        setVolume(vol);
      }

      if (e.key === "ArrowLeft") {
        e.preventDefault();
        // setShowControls(true)
        controlsHandler();
        isSeeking = true;
        seekingTime -= 5;
        seekingTime = seekingTime < 0 ? 0 : seekingTime;
        onSeek(seekingTime);
        seeker.current.value = seekingTime;
      }

      if (e.key === "ArrowRight") {
        e.preventDefault();
        controlsHandler();
        // setShowControls(true)
        isSeeking = true;
        seekingTime += 5;
        seekingTime = seekingTime > duration ? duration : seekingTime;
        onSeek(seekingTime);
        seeker.current.value = seekingTime;
      }
    },
    [isPlaying, volumeLevel, seekingTime]
  );

  const handleWindowResize = useCallback(() => {
    let width = window.innerWidth,
      height = window.innerHeight,
      size = "1.8vw";

    if (width < height && document.fullscreenElement) {
      // console.log(playerState.showSummaries);
      // if (!showSummaries) {
      // }
      // toggleFullscreen();
    }

    if (width < height && !showSummaries)
      dispatch(playerActions.toggleSummariesAction.action());

    if (width < 860) {
      size = "17px";
    } else if (width > 1000) {
      size = "20px";
    }

    setTimeShowerSize(size);
  }, [showSummaries]);

  const show_volume = () => {
    clearTimeout(showVolumeTimeout);

    setShowVolume(true);
    showVolumeTimeout = setTimeout(() => {
      setShowVolume(false);
    }, 1500);
  };

  useEffect(() => {
    handleWindowResize();

    // overlayContainer.current.addEventListener('keyup', (e) => {

    // 	if (e.key === 'ArrowLeft') {
    // 		e.preventDefault();
    // 		// setShowControls(false)
    // 		isSeeking = false;
    // 	}

    // 	if (e.key === 'ArrowRight') {
    // 		e.preventDefault();
    // 		// setShowControls(false)
    // 		isSeeking = false;
    // 	}
    // })

    window.addEventListener("resize", (e) => {
      handleWindowResize();
    });

    return () => {
      window.removeEventListener("resize", () => handleWindowResize);
    };
  }, []);

  useEffect(() => {
    showSummaries = playerState.showSummaries;
  }, [playerState.showSummaries]);

  useEffect(() => {
    if (!isSeeking) {
      seeker.current.value = currentTime;
      seekingTime = currentTime;
    }
  }, [currentTime]);

  useEffect(() => {
    volumeController.current.value = volumeLevel;
    if (!isMuted) volumeRemember = volumeLevel;
  }, [volumeLevel, isMuted]);

  useEffect(() => {
    if (!bigPlayButtonContainer.current) return;
    if (showControls) {
      bigPlayButtonContainer.current.style.opacity =
        controlsContainer.current.style.opacity = 1;
      shareContainer.current.style.opacity =
        controlsContainer.current.style.opacity = 1;
    } else {
      bigPlayButtonContainer.current.style.opacity =
        controlsContainer.current.style.opacity = 0;
      shareContainer.current.style.opacity =
        controlsContainer.current.style.opacity = 0;
    }
  }, [showControls]);

  useEffect(() => {
    // console.log(videoState)
    if (videoState === 0 || videoState === -1) {
      clearTimeout(mouseMovingTimeout);
      setShowControls(true);
      clearTimeout(backgroundGradientTimeout);
      setShowBackgroundGradient(true);
      // console.log('adding gradient')
    } else if (videoState === 2) {
      clearTimeout(backgroundGradientTimeout);
      setShowBackgroundGradient(true);
      // console.log('adding gradient')
    } else {
      // console.log('removing gradient')
      removeGradient();
    }
  }, [videoState]);

  const previousChapter = () => {
    let summarys = summaryObject.summaries;
    for (let i = 0; i < summarys.length; i++) {
      if (i === summarys.length - 1) {
        onSeek(summarys[i - 1].start);
        break;
      } else if (
        summarys[i].start <= currentTime &&
        summarys[i + 1].start > currentTime &&
        i !== 0
      ) {
        onSeek(summarys[i - 1].start);
        break;
      } else if (
        summarys[i].start <= currentTime &&
        summarys[i + 1].start > currentTime &&
        i === 0
      ) {
        onSeek(summarys[0].start);
        break;
      }
    }
  };

  const nextChapter = () => {
    let summarys = summaryObject.summaries;

    for (let i = 0; i < summarys.length; i++) {
      if (summarys[i].start > currentTime) {
        onSeek(summarys[i].start);
        break;
      }
    }
  };

  return (
    <div
      id="overlayContainer"
      tabIndex={1}
      ref={overlayContainer}
      onMouseEnter={controlsHandler}
      onMouseLeave={() => {
        if (showSettings || currentTime === 0.01 || !isPlaying) return;
        setShowControls(false);
        clearTimeout(mouseMovingTimeout);
      }}
      onKeyDown={(e) => {
        handleKeydownEvent(e);
      }}
    >
      {/*feedback and share buttons*/}
      <IconContext.Provider value={{ color: "white", size: "30px" }}>
        <ShareAndFeedback ref={shareContainer}>
          <ShareContainer
            onClick={() => {
              window.open(
                "https://bit.ly/chapterme_product_feedback",
                "_blank"
              );
            }}
          >
            <VscFeedback />
            <span style={{ color: "white", height: "25px" }}>Feedback</span>
          </ShareContainer>
          <ShareContainer
            onClick={() => {
              onCopyVideo(1);
            }}
          >
            <AiOutlineShareAlt />
            <span style={{ color: "white", height: "25px" }}>Share</span>
          </ShareContainer>
        </ShareAndFeedback>
      </IconContext.Provider>

      {videoState !== -1 ? (
        <TransparentScreen
          background={showBackgroundGradient}
          videoState={videoState}
          onMouseMove={() => {
            if ((!showSettings || currentTime !== 0) && window.innerWidth > 800)
              controlsHandler();
          }}
        >
          <LeftSeekScreen
            onClick={(e) => {
              if (e.timeStamp - lastSeekTime < 350) {
                seekingTime = currentTime - 10;
                onSeek(seekingTime > 0 ? seekingTime : 0);
              } else controlsHandler();
              lastSeekTime = e.timeStamp;
            }}
          >
            <SeekingAnimeContainer
              inverse={true}
              id="leftSeekingAnimeContainer"
            >
              <LeftSeekingAnimeContainer>
                <AnimatedPlayPauseBtn
                  play={true}
                  seek={true}
                  style={{ opacity: 0 }}
                />
                <AnimatedPlayPauseBtn
                  play={true}
                  seek={true}
                  style={{ opacity: 0 }}
                />
                <AnimatedPlayPauseBtn
                  play={true}
                  seek={true}
                  style={{ opacity: 0 }}
                />
              </LeftSeekingAnimeContainer>
              <div style={{ marginTop: "10px" }}>{seekedTime} seconds</div>
            </SeekingAnimeContainer>
          </LeftSeekScreen>

          <MiddlePausePlayScreen
            onMouseUp={(e) => {
              if (e.timeStamp - lastSeekTime < 350 || showControls) {
                if (!isPlaying) {
                  clearTimeout(mouseMovingTimeout);
                  if (videoState === -1)
                    mouseMovingTimeout = setTimeout(() => {
                      setShowControls(false);
                    }, 5000);
                  else setShowControls(false);
                } else {
                  clearTimeout(mouseMovingTimeout);
                  setShowControls(true);
                }
                onPlay(!isPlaying);
              } else {
                controlsHandler();
              }
              lastSeekTime = e.timeStamp;
            }}
          >
            {/*middle control button*/}
            <div
              ref={(el) => {
                bigPlayButtonContainer.current = el;
              }}
            >
              {!isPlaying && (videoState === -1 || videoState === 0) ? (
                <BigPlaybuttonContainer
                  background={themeColor}
                  ref={(el) => {
                    bigPlayButton.current = el;
                  }}
                >
                  {videoState === -1 ? (
                    <BigPlaybutton />
                  ) : (
                    <MdRefresh size="40" color="white" />
                  )}
                </BigPlaybuttonContainer>
              ) : null}
            </div>
            {platform !== "wistia" && (
              <AnimatedPlayPauseBtnContainer
                ref={(el) => {
                  animatedPlayPause.current = el;
                }}
                id="playPause-anime-btn"
              >
                <AnimatedPlayPauseBtn play={isPlaying} />
              </AnimatedPlayPauseBtnContainer>
            )}
          </MiddlePausePlayScreen>

          <RightSeekScreen
            onClick={(e) => {
              if (e.timeStamp - lastSeekTime < 350) onSeek(currentTime + 10);
              else controlsHandler();
              lastSeekTime = e.timeStamp;
            }}
          >
            <SeekingAnimeContainer id="rightSeekingAnimeContainer">
              <RightSeekingAnimeContainer>
                <AnimatedPlayPauseBtn
                  play={true}
                  seek={true}
                  style={{ opacity: 0 }}
                />
                <AnimatedPlayPauseBtn
                  play={true}
                  seek={true}
                  style={{ opacity: 0 }}
                />
                <AnimatedPlayPauseBtn
                  play={true}
                  seek={true}
                  style={{ opacity: 0 }}
                />
              </RightSeekingAnimeContainer>
              <div style={{ marginTop: "10px" }}>{seekedTime} seconds</div>
            </SeekingAnimeContainer>
          </RightSeekScreen>
        </TransparentScreen>
      ) : null}

      {showChapters === "true" && (
        <SummaryTogglebar
          borderColor={themeColor}
          showSummaries={playerState.showSummaries}
          onClick={() => {
            if (chaptersToggle === "false") return;
            dispatch(playerActions.toggleSummariesAction.action());
          }}
        >
          <div
            style={{
              transform: playerState.showSummaries
                ? "rotate(180deg) translate(0, 50%)"
                : "translate(0, -50%)",
            }}
          >
            <AiOutlineDoubleLeft size="17px" color="white" />
          </div>
        </SummaryTogglebar>
      )}

      {/*bottom navbar*/}
      <ControlsPanel
        ref={controlsContainer}
        background={themeColor}
        onMouseEnter={() => {
          clearTimeout(mouseMovingTimeout);
        }}
        onClick={() => {
          clearTimeout(mouseMovingTimeout);
        }}
        onPointerUp={() => {
          hoveringTimeShower.current.style.display = "none";
        }}
      >
        {/*Play pause button mini*/}
        <ControlIconsContainers style={{ width: "20px" }}>
          <AnimatedPlayPauseBtnSmall
            play={!isPlaying}
            onClick={() => {
              onPlay(!isPlaying);
            }}
          />
        </ControlIconsContainers>

        {/*Current time*/}
        <TimeDisplayer>{cur_time}</TimeDisplayer>

        {/* seeker */}
        <div className="seeker-container">
          <Seeker
            className="seeker"
            max={duration}
            ref={seeker}
            onMouseEnter={() => {
              hoveringTimeShower.current.style.display = "block";
            }}
            onMouseLeave={() => {
              hoveringTimeShower.current.style.display = "none";
            }}
            onMouseMove={(e) => {
              seekerMouseMoveWorking = true;
              let offsetX = e.nativeEvent.offsetX,
                clientWidth = e.target.clientWidth;
              if (offsetX > clientWidth || offsetX < 0) return;
              let value = parseInt((offsetX / clientWidth) * duration);
              setHoveringTime(format(value));
              hoveringTimeShower.current.style.left = `${offsetX - 20}px`;
            }}
            onPointerDown={(e) => {
              isSeeking = true;
              let value = parseInt(
                (e.nativeEvent.offsetX / e.target.clientWidth) * duration
              );
              value = value < 0 ? 0 : value;
              seekTo(value);
              hoveringTimeShower.current.style.display = "block";
            }}
            onPointerUp={(e) => {
              isSeeking = false;
              let value = parseInt(
                (e.nativeEvent.offsetX / e.target.clientWidth) * duration
              );
              value = value < 0 ? 0 : value;
              seekTo(value);
            }}
            onTouchMove={(e) => {
              if (seekerMouseMoveWorking) return;
              let clientWidth = e.target.clientWidth;
              let rect = e.target.getBoundingClientRect();
              let x = e.changedTouches[0].clientX - rect.left;
              if (x > clientWidth || x < 0) return;
              let value = parseInt((x / e.target.clientWidth) * duration);
              setHoveringTime(format(value));
              hoveringTimeShower.current.style.left = `${x - 20}px`;
            }}
            onTouchEnd={(e) => {
              if (!seekerMouseMoveWorking) {
                hoveringTimeShower.current.style.display = "none";
                let clientWidth = e.target.clientWidth;
                let rect = e.target.getBoundingClientRect();
                let x = e.changedTouches[0].clientX - rect.left;
                if (x > clientWidth || x < 0) return;
                let value = parseInt((x / e.target.clientWidth) * duration);
                seekTo(value);
              }
            }}
          />
          <span ref={hoveringTimeShower}>{hoveringTime}</span>
        </div>

        {/*volume controller and speaker btn*/}
        <VolumeControls onMouseMove={show_volume} onTouchEnd={show_volume}>
          <IconContext.Provider value={{ color: "white", size: "1.6rem" }}>
            {isMuted ? (
              <ControlIconsContainers>
                <MdVolumeOff
                  onClick={() => {
                    if (showVolume) setVolume(volumeRemember);
                  }}
                />
              </ControlIconsContainers>
            ) : (
              <ControlIconsContainers>
                {volumeLevel < volume / 3 ? (
                  <BsFillVolumeDownFill
                    onClick={() => {
                      if (showVolume) setVolume(0);
                    }}
                  />
                ) : (
                  <BsFillVolumeUpFill
                    onClick={() => {
                      if (showVolume) setVolume(0);
                    }}
                  />
                )}
              </ControlIconsContainers>
            )}
          </IconContext.Provider>
          {/*volume controller*/}
          <VolumeSeekerContainer
            themeColor={themeColor}
            showVolume={showVolume}
          >
            <VolumeSeeker
              max={volume}
              ref={volumeController}
              onInput={(evt) => {
                setVolume(evt.target.value);
              }}
            />
          </VolumeSeekerContainer>
        </VolumeControls>

        {/* settings */}
        <SettingsContainer>
          {hideFullscreen ? null : (
            <ControlIconsContainers style={{ position: "static" }}>
              <SettingIcons
                id="settings-icon"
                onClick={() => {
                  setShowSetting(!showSettings);
                  setShowColorPicker(false);
                }}
              />

              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100vh",
                  display: `${showSettings ? "block" : "none"}`,
                  backgroundColor: "rgba(0, 0, 0, .0)",
                  bottom: "0px",
                  right: "0px",
                }}
                onClick={() => {
                  setShowSetting(false);
                  setShowColorPicker(false);
                }}
              ></div>
              {showSettings ? (
                <ShowSettings font={timeShowerSize}>
                  {clearTimeout(mouseMovingTimeout)}
                  <Settings
                    changePlaybackSpeed={changePlaybackSpeed}
                    setShowColorPicker={setShowColorPicker}
                    playingSpeed={playingSpeed}
                  />
                </ShowSettings>
              ) : null}
            </ControlIconsContainers>
          )}
        </SettingsContainer>

        {/* Full1screen */}
        {hideFullscreen ? null : (
          <ControlIconsContainers id="fullscreen-btn">
            <Fullscreen
              onClick={() => {
                // if (document.fullscreenElement && !showSummaries) {
                //   showSummaries = true;
                //   dispatch(playerActions.toggleSummariesAction.action());
                // }
                toggleFullscreen();
              }}
            />
          </ControlIconsContainers>
        )}

        {/*chapterme logo*/}
        <ControlIconsContainers
          id="player-logo"
          hideFullscreen={hideFullscreen}
          onClick={() => {
            window.open("https://chapterme.co", "_blank");
          }}
        >
          <LogoImg
            hideFullscreen={hideFullscreen}
            normal={true}
            src={logo || "https://chapterme.co/assets/img/logo-white.svg"}
            alt=""
            style={{ maxWidth: "110px" }}
          />
          <LogoImg
            hideFullscreen={hideFullscreen}
            src={icon || Chapterme_short_logo}
            alt=""
          />
        </ControlIconsContainers>
      </ControlsPanel>
    </div>
  );
};

export default Overlay;
