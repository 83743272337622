import {setCurrentPlayingTimeAction, toggleSummariesAction} from './actions'
import {fetchSummariesAsyncActions} from '../summaries/actions'

const initialState = {
	currentPlayingTime: 0,
	showSummaries: true,
}

export default (state = initialState, action) => {
	switch(action.type) {
		case setCurrentPlayingTimeAction.actionName: {
			return {...state, currentPlayingTime: action.payload}
		}

		case toggleSummariesAction.actionName: {
			return {...state, showSummaries: !state.showSummaries}
		}

		case fetchSummariesAsyncActions.actionNames.request:
			return {...state, currentPlayingTime: 0, showSummaries: true,}

		default:
			return {...state}
	}
}